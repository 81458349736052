import CONFIG from '../../config.json';

import React from 'react';

import {
  Box,
  Container,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction
} from '@material-ui/core';

import {
  Link,
  withRouter
} from 'react-router-dom';

import {
  withStyles
} from '@material-ui/core/styles';

import {
  ArrowForwardIos as ArrowNextIcon
} from '@material-ui/icons/';

import {
  HeaderBar,
  PageHeader,
  Footer
} from '../../components';

import {
  API
} from '../../utils';

const useStyles = theme => ({
  preTitle: {
    opacity: .35
  },
  listItem: {
    paddingTop: 16,
    paddingBottom: 16
  },
  listArrow: {
    display: 'flex',
    alignItems: 'center'
  },
  listArrowText: {
    fontSize: theme.typography.h5.fontSize,
    opacity: .5
  },
  listArrowIcon: {
    marginLeft: 6
  }
})

export default withStyles(useStyles)(withRouter(class OperatorCategoryList extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      list: undefined
    };
  }

  componentDidMount () {
    API.categories()
      .then(response => {
        if (response.status === 200) {
          this.setState({
            list: response.data.data
          })
        }
      });
  }

  render () {
    return (
      <React.Fragment>
        <HeaderBar
          title={ (() => {
            return (
              <span>
                {
                  CONFIG.composed_title.split('|').map((text, index) => {
                    return index % 2 === 0
                      ? <span key={ index }>{ text }</span>
                      : <span key={ index } style={{ fontWeight: 300 }}>{ text }</span>
                  })
                }
              </span>)
          })()}
        />

        <PageHeader
          maxWidth="lg"
          simple
        >
          <Typography
            variant="h4"
            component="h2"
            className={ this.props.classes.preTitle }>
              Attività a te associate
          </Typography>
        </PageHeader>

        <main>
          <Container maxWidth="lg">
            {
              (this.state.list && this.state.list.length > 0) &&
                <List component="nav" aria-label="activity list">
                  {
                    this.state.list.map(item => {
                      return (
                        <ListItem
                          className={ this.props.classes.listItem }
                          divider
                          key={ item.id }
                          disabled={ item.companies_count === 0 }
                          button
                          component={ Link }
                          to={ `/operator/categories/${item.id}` }
                          >
                            <ListItemText
                              primaryTypographyProps={{ variant: 'h2' }}
                              primary={ item.name } />
                            <ListItemSecondaryAction
                              edge="end"
                              className={ this.props.classes.listArrow }>
                                <Box className={ this.props.classes.listArrowText }>{ item.companies_count }</Box>
                                <ArrowNextIcon className={ this.props.classes.listArrowIcon } />
                            </ListItemSecondaryAction>
                        </ListItem>
                      )
                    })
                  }
                </List>
            }

            {
              (this.state.list && this.state.list.length === 0) &&
                <Typography>Non ci sono risulati</Typography>
            }

          </Container>
        </main>

        <Footer />
      </React.Fragment>
    )
  }
}))
