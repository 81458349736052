import React from 'react';

import {
  Container,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Typography
} from '@material-ui/core';

import {
  Link,
  withRouter
} from 'react-router-dom';

import {
  withStyles
} from '@material-ui/core/styles';

import {
  ArrowForwardIos as ArrowNextIcon
} from '@material-ui/icons';

import * as moment from 'moment';

import {
  HeaderBar,
  PageHeader,
  Footer,
  TrafficLight
} from '../../components';

import {
  province as provinceJSON,
  comuni as comuniJSON
} from '../../utils/json';

import {
  API
} from '../../utils';

const useStyles = theme => ({
  preTitle: {
    opacity: .35
  },
  listArrow: {
    display: 'flex',
    alignItems: 'center'
  },
  listItemText: {
    textTransform: 'capitalize'
  }
})

moment.locale('it');

export default withStyles(useStyles)(withRouter(class OperatorCompany extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      province: undefined,
      city: undefined,
      company: undefined,
      days: undefined
    };
  }

  getDayStatus = (item) => {
    const today = moment(new Date());

    if (moment(item.date).format('YYYY-MM-DD') === today.format('YYYY-MM-DD')) {
      const lastSlot = item.time_slots[item.time_slots_count - 1];
      const lastSlotEndTime = moment(`${item.date} ${lastSlot.to}`);

      if (today > lastSlotEndTime) {
        return 'danger';
      }
    }

    // NB: success status rate is 30% of 'actual_capacity'
    const thirthyPerc = Math.round(item.actual_capacity * 30 / 100);

    if (item.booking_count <= thirthyPerc) {
      return 'success';
    } else if (item.booking_count > thirthyPerc && item.booking_count < item.actual_capacity) {
      return 'warning';
    } else {
      return 'danger';
    }
  }

  componentDidMount () {
    API.days(this.props.match.params.id, 1, 32)
      .then(response => {
        if (response.status === 200) {
          const company = response.data.company;
          const days = response.data.days;

          const province = provinceJSON.find(item => {
            return item.nome.toLowerCase() === company.province.toLowerCase()
          });

          const city = comuniJSON.find(item => {
            return item.nome.toLowerCase() === company.city.toLowerCase()
          });

          this.setState({
            province,
            city,
            company,
            days
          });
        }
      });
  }

  render () {
    return (
      <React.Fragment>

        <HeaderBar
          back={
            this.state.company
            ? `/operator/categories/${this.state.company.company_category_id}`
            : false
          }
          title={ this.state.company ? this.state.company.category.name : '' }
        />

        <PageHeader
          maxWidth="lg"
          sticky
          simple
          title={ this.state.company ? this.state.company.group.name : '' }
          text={ this.state.company ? this.state.company.address : '' }
        />

        {/* TOOD: set infinite loader to calendar scroll */}

        <main>
          <Container maxWidth="lg">
            {
              (this.state.days && this.state.days.length > 0) &&
                <List component="nav" aria-label="activity list">
                  {
                    this.state.days.map(item => {
                      const date = moment(item.date);

                      return (
                        <ListItem
                          divider
                          key={ item.date }
                          disabled={ item.time_slots_count === 0 }
                          button
                          component={ Link }
                          to={ `/operator/company/${this.state.company.id}/${item.date}` }
                          >
                            <ListItemText
                              className={ this.props.classes.listItemText }
                              primaryTypographyProps={{ variant: 'h2', style:{ marginBottom: 6 } }}
                              primary={ date.format('dddd DD') }
                              secondary={ date.format('MMMM') }
                              secondaryTypographyProps={{ variant: 'subtitle2' }} />
                            <ListItemSecondaryAction
                              edge="end"
                              className={ this.props.classes.listArrow }>
                                {
                                  item.time_slots_count !== 0 &&
                                    <TrafficLight variant={ this.getDayStatus(item) }/>
                                }
                                <ArrowNextIcon />
                            </ListItemSecondaryAction>
                        </ListItem>
                      )
                    })
                  }
                </List>
            }

            {
              (this.state.days && this.state.days.length === 0) &&
                <Typography>Non ci sono risulati</Typography>
            }
          </Container>
        </main>

        <Footer />
      </React.Fragment>
    )
  }
}))
