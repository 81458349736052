import CONFIG from '../../config.json';

import React from 'react';

import {
  Box,
  Button,
  Container,
  Divider,
  TextField,
  Typography,
  InputAdornment
} from '@material-ui/core';

import {
  withRouter
} from 'react-router-dom';

import ReCAPTCHA from "react-google-recaptcha";

import {
  PageHeader,
  Footer
} from '../../components';

import {
  API
} from '../../utils';

export default withRouter(class CustomerLogin extends React.Component {
  constructor (props) {
    super(props);

    this.recaptchaRef = React.createRef();

    this.state = {
      phone: '',
      code: '',
      sent: false,
      validator: {
        phone: {
          valid: true,
          errorMessage: 'Il numero non è valido'
        },
        code: {
          valid: true,
          errorMessage: undefined
        }
      }
    };
  }

  getSendButtonText = () => {
    return this.state.sent
      ? 'Rinviami il codice'
      : 'Inviami il codice';
  }

  validatePhone = () => {
    const validator = this.state.validator;
    const mobilePhoneRegex = /^(?:\+39)\d{10}$/;
    const isPhoneValid = `+39${this.state.phone}`.match(mobilePhoneRegex) !== null;

    validator.phone.valid = isPhoneValid;

    this.setState({ validator });

    return isPhoneValid;
  }

  validateCode = () => {
    const validator = this.state.validator;
    const isCodeValid = this.state.code.length === 6;

    if (isCodeValid) {
      validator.code.valid = true;
      validator.code.errorMessage = undefined;
    } else {
      validator.code.valid = false;
      validator.code.errorMessage = 'Il codice deve essere di 6 cifre';
    }

    this.setState({ validator});

    return isCodeValid;
  }

  onPhoneChange = (e) => {
    if (e.target.value.length <= 10) {
      if (e.target.value.length === 0 || e.target.value.match(/^([+-]?[1-9]\d*|0)$/) !== null) {
        this.setState({ phone: e.target.value });
      }
    }
  }

  onReCaptchaChange = (e) => {
    API.register(`+39${this.state.phone}`)
      .then(response => {
        if (response.status === 200) {
          this.setState({ sent: true });
        }
      });
  }

  onSendCodeButtonClick = () => {
    const isPhoneValid = this.validatePhone();

    if (isPhoneValid) {
      this.recaptchaRef.current.execute();
    }
  }

  onCodeChange = (e) => {
    if (e.target.value.length <= 6) {
      this.setState({ code: e.target.value })
    }
  }

  onConfirmCodeButtonClick = () => {
    const isPhoneValid = this.validatePhone();
    const isCodeValid = this.validateCode();

    if (isPhoneValid && isCodeValid) {
      API.login('password', CONFIG.client_id, CONFIG.client_secret, `+39${this.state.phone}`, this.state.code)
        .then(response => {
          if (response.status === 200) {
            this.props.history.push('/search');
          }
        })
        .catch(error => {
          if (error.response && error.response.status === 400) {
            const validator = this.state.validator;

            validator.code.valid = false;
            validator.code.errorMessage = 'Il codice non è valido';

            this.setState({ validator });
          }
        });
    }
  }

  componentDidMount () {
    const apiToken = localStorage.getItem(`${CONFIG.app_prefix}_token`);
    const userRole = localStorage.getItem(`${CONFIG.app_prefix}_role`);

    if (apiToken && userRole === 'user') {
      this.props.history.push('/search');
    }
  }

  render () {
    return (
      <React.Fragment>
        <PageHeader bigTitle="Accedi" />

        <main>
          <Container maxWidth="lg">
            <Box mb={ 3 }>
              <Typography>Inserisci il tuo numero di cellulare e il codice che riceverai via SMS</Typography>
            </Box>

            <Box mb={ 1 }>
              <TextField
                error={ !this.state.validator.phone.valid }
                helperText={
                  !this.state.validator.phone.valid
                    ? this.state.validator.phone.errorMessage
                    : (this.state.sent ? 'Il codice ti è stato inviato con successo' : '') }
                autoComplete="off"
                fullWidth
                label="Telefono"
                type="number"
                variant="filled"
                value={ this.state.phone }
                onChange={ this.onPhoneChange }
                InputProps={{
                  startAdornment: <InputAdornment position="start">+39</InputAdornment>
                }}>
              </TextField>

              <ReCAPTCHA
                ref={ this.recaptchaRef }
                sitekey={ CONFIG.captcha_key }
                size="invisible"
                onChange={ this.onReCaptchaChange }
              />
            </Box>

            <Box mb={ 3 }>
              <Button
                variant="contained"
                color="primary"
                size="large"
                onClick={ this.onSendCodeButtonClick }
                fullWidth>
                  { this.getSendButtonText() }
              </Button>
            </Box>

            {
              this.state.sent &&
                <Box>
                  <Box mt={ 4 } mb={ 3 }>
                    <Divider />
                  </Box>

                  <Box mb={ 3 }>
                    <Typography>Attendi qualche istante</Typography>
                  </Box>

                  <Box mb={ 1 }>
                    <TextField
                      autoComplete="one-time-code"
                      error={ !this.state.validator.code.valid }
                      helperText={ !this.state.validator.code.valid ? this.state.validator.code.errorMessage : '' }
                      fullWidth
                      label="Codice a 6 cifre"
                      variant="filled"
                      type="number"
                      inputProps={{
                        maxLength: 6
                      }}
                      value={ this.state.code }
                      onChange={ this.onCodeChange }
                    />
                  </Box>

                  <Box mb={ 3 }>
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      fullWidth
                      onClick={ this.onConfirmCodeButtonClick }>
                        Conferma
                    </Button>
                  </Box>
                </Box>
            }
          </Container>
        </main>

        <Footer />
      </React.Fragment>
    )
  }
})
