import CONFIG from '../../config.json';

import React from 'react';

import {
  Box,
  Container,
  Typography,
  List,
  ListItem,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions
} from '@material-ui/core';

import {
  withStyles
} from '@material-ui/core/styles';

import * as moment from 'moment';

import {
  withRouter
} from 'react-router-dom';

import {
  PageHeader,
  SubPageHeader,
  Footer,
  HeaderBar,
  BrandBorder
} from '../../components';

import {
  API
} from '../../utils';

moment.locale('it');

const useStyles = theme => ({
  listButtonGroup: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginLeft: -8,
    marginRight: -8
  }
})

export default withStyles(useStyles)(withRouter(class CustomerProfile extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      data: undefined,
      booking: undefined,
      selectedBooking: undefined,
      deleteDialog: false,
      codeDialog: false
    };
  }

  onBookingDeleteButtonClick = (item) => {
    this.setState({
      selectedBooking: item,
      deleteDialog: true
    });
  }

  onDeleteDialogClose = () => {
    this.setState({
      selectedBooking: undefined,
      deleteDialog: false
    });
  }

  onDeleteDialogAbort = () => {
    this.setState({
      selectedBooking: undefined,
      deleteDialog: false
    });
  }

  onDeleteDialogConfirm = () => {
    API.deleteBooking(this.state.selectedBooking.id)
      .then(response => {
        if (response.status === 200) {
          const booking = this.state.booking.filter(item => {
            return item.id !== this.state.selectedBooking.id;
          });

          this.setState({
            booking,
            selectedBooking: undefined,
            deleteDialog: false
          });
        }
      })
  }

  onBookingShowCodeButtonClick = (item) => {
    this.setState({
      selectedBooking: item,
      codeDialog: true
    });
  }

  onCodeDialogClose = () => {
    this.setState({
      selectedBooking: undefined,
      codeDialog: false
    });
  }

  componentDidMount () {
    API.getBooking()
      .then(response => {
        if (response.status === 200) {
          this.setState({
            booking: response.data.data
          })
        }
      });
  }

  render () {
    return (
      <React.Fragment>
        <HeaderBar
          back="/search"
          title="Posizione"
        />

        <PageHeader
          simple
          sticky
          title="Le tue prenotazioni"
          text={ `${this.state.booking ? this.state.booking.length : '0' }/4 Disponibili` }
        />

        <SubPageHeader text="Ricorda di eliminare le tue prenotazioni se cambi programmi." />

        <main>
          <Container maxWidth="lg">
            {
              this.state.booking && this.state.booking.length > 0 &&
                <List aria-label="booking list">
                  {
                    this.state.booking.map(item => {
                      return (
                        <ListItem
                          divider
                          key={ item.id }
                        >
                          <Box width="100%">
                            <Typography variant="h4" gutterBottom>{ item.booking_time_slot.company_time_slot.company.group.name }</Typography>
                            <Typography variant="subtitle2">{ item.booking_time_slot.company_time_slot.company.address }, { item.booking_time_slot.company_time_slot.company.city }</Typography>
                            {
                              (item.name && item.lastname) &&
                                <Typography variant="subtitle2">{ `${item.name} ${item.lastname} (${ moment(item.birthdate).format('DD/MM/YYYY') })` }</Typography>
                            }

                            <Box mt={2} mb={1}>
                              <Typography variant="h4">
                                { moment(item.booking_time_slot.date).format('DD/MM') } dalle {item.booking_time_slot.from.slice(0,5)} alle {item.booking_time_slot.to.slice(0,5)}
                              </Typography>
                            </Box>
                            <Box className={ this.props.classes.listButtonGroup }>
                              <Button color="primary" onClick={ () => { this.onBookingDeleteButtonClick(item) } }>Elimina</Button>
                              <Button color="primary" onClick={ () => { this.onBookingShowCodeButtonClick(item) } }>Mostra Codice</Button>
                            </Box>
                          </Box>
                        </ListItem>
                      )
                    })
                  }
                </List>
            }

            {
              this.state.booking && this.state.booking.length === 0 &&
                <Typography>Non hai effettuato nessuna prenotazione</Typography>
            }
          </Container>
        </main>

        <Footer />

        <Dialog open={ this.state.deleteDialog } onClose={ this.onDeleteDialogClose }>
          <DialogTitle>Disdici Prenotazione</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Sei sicuro di voler disdire questa prenotazione?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={ this.onDeleteDialogAbort }>Annulla</Button>
            <Button color="primary" onClick={ this.onDeleteDialogConfirm }>Elimina</Button>
          </DialogActions>
        </Dialog>

        <Dialog open={ this.state.codeDialog } onClose={ this.onCodeDialogClose }>
          <DialogTitle>
            <Box textAlign="center">
              <Typography variant="h4" style={{ fontWeight: 700 }}>
                {
                  CONFIG.composed_title.split('|').map((text, index) => {
                    return index % 2 === 0
                      ? <span key={ index }>{ text }</span>
                      : <span key={ index } style={{ fontWeight: 300 }}>{ text }</span>
                  })
                }
              </Typography>
              <Typography variant="h6" style={{ fontWeight: 700, fontSize: 16 }}>Entra senza fare la coda</Typography>
            </Box>
          </DialogTitle>
          <BrandBorder />
          <DialogContent dividers>
            {
              this.state.selectedBooking &&
                <Box textAlign="center">
                  <Typography variant="h1" style={{ fontWeight: 700 }}>{ this.state.selectedBooking.code }</Typography>
                  <Typography variant="h6" style={{ fontWeight: 700, fontSize: 16 }}>
                    { moment(this.state.selectedBooking.booking_time_slot.date).format('DD MMMM') } • {this.state.selectedBooking.booking_time_slot.from.slice(0,5)} - {this.state.selectedBooking.booking_time_slot.to.slice(0,5)}
                  </Typography>
                </Box>
            }
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={ this.onCodeDialogClose }>Chiudi</Button>
          </DialogActions>
        </Dialog>

      </React.Fragment>
    )
  }
}))
