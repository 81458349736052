import { createMuiTheme } from "@material-ui/core";

const THEME_GUTTERS = 30;

const theme = createMuiTheme({
  palette: {
    type: 'dark',
    primary: {
      light: '#588ff4',
      main: '#007AFF',
      dark: '#175bd8',
      contrastText: '#fff'
    },
    error: {
      light: '#e57373',
      main: '#b92c2c',
      dark: '#d32f2f',
      contrastText: 'rgba(0, 0, 0, 0.87)'
    },
    warning: {
      light: '#ffb74d',
      main: '#deb400',
      dark: '#f57c00',
      contrastText: 'rgba(0, 0, 0, 0.87)'
    },
    success: {
      light: '#81c784',
      main: '#50ad50',
      dark: '#388e3c',
      contrastText: 'rgba(0, 0, 0, 0.87)'
    },
    grey: {
      '50': '#fafafa',
      '100': '#f5f5f5',
      '200': '#eeeeee',
      '300': '#e0e0e0',
      '400': '#bdbdbd',
      '500': '#9e9e9e',
      '600': '#707070',
      '700': '#616161',
      '800': '#424242',
      '900': '#282828',
      'A100': '#d5d5d5',
      'A200': '#aaaaaa',
      'A400': '#303030',
      'A700': '#616161'
    },
    background: {
      paper: '#fff',
      default: '#1E1E1E',
      light: '#282828'
    },
    border: '#707070'
  },
  typography: {
    fontFamily: [
      '"Helvetica Neue"',
      'Lato',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    htmlFontSize: 22,
    fontSize: 22,
    h1: {
      fontWeight: 500,
      fontSize: 52
    },
    h2: {
      fontWeight: 500,
      fontSize: 32
    },
    h3: {
      fontWeight: 500,
      fontSize: 26
    },
    h4: {
      fontWeight: 500,
      fontSize: 24
    },
    h5: {
      fontWeight: 500,
      fontSize: 20
    },
    h6: {
      fontWeight: 500,
      fontSize: 18
    },
    body1: {
      // fontSize: 22,
      fontWeight: 500
    },
    caption: {
      fontWeight: 500
    },
    overline: {
      fontWeight: 500
    },
    subtitle1: {
      fontSize: 20,
      color: '#c6c6c6',
      fontWeight: 500,
      lineHeight: 1.35
    },
    subtitle2: {
      fontSize: 16,
      color: '#fff',
      fontWeight: 400,
      lineHeight: 1.35
    },
  },
  mixins: {
    gutters: THEME_GUTTERS
  },
  overrides: {
    MuiContainer: {
      root: {
        paddingRight: THEME_GUTTERS,
        paddingLeft: THEME_GUTTERS
      }
    },
    MuiDivider: {
      root: {
        backgroundColor: '#707070'
      }
    },
    MuiButton: {
      root: {
        textTransform: 'none',
        fontWeight: 700,
        borderRadius: 30,
        '&.Mui-disabled': {
          opacity: .25
        }
      }
    },
    MuiFormHelperText: {
      contained: {
        marginLeft: 24,
        marginRight: 24
      }
    },
    MuiInputLabel: {
      filled: {
        left: 12
      }
    },
    MuiInputAdornment: {
      filled: {
        marginLeft: 12
      }
    },
    MuiSelect: {
      iconFilled: {
        right: 14
      }
    },
    MuiFilledInput: {
      root: {
        borderTopLeftRadius: 30,
        borderTopRightRadius: 30,
        borderBottomLeftRadius: 30,
        borderBottomRightRadius: 30,
        overflow: 'hidden',
        backgroundColor: '#2C2C2C',
        border: '1px solid #707070',
        '&.Mui-disabled': {
          opacity: .2
        }
      },
      input: {
        padding: '27px 24px 10px',
        fontWeight: 500
      },
      underline: {
        '&:after': {
          display: 'none'
        },
        '&:before': {
          display: 'none'
        }
      }
    },
    MuiList: {
      root: {
        marginLeft: -THEME_GUTTERS,
        marginRight: -THEME_GUTTERS,
      }
    },
    MuiListItem: {
      root: {
        '&.Mui-disabled': {
          opacity: 1,
          '&:before': {
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: 'block',
            zIndex: 2,
            backgroundColor: '#2C2C2C',
            opacity: .9,
            content: '""'
          }
        }
      },
      gutters: {
        paddingRight: THEME_GUTTERS,
        paddingLeft: THEME_GUTTERS
      }
    },
    MuiListItemSecondaryAction: {
      root: {
        right: (THEME_GUTTERS / 2) + 8,
      }
    },
    MuiMenu: {
      list: {
        paddingTop: 0,
        paddingBottom: 0,
        marginLeft: 0,
        marginRight: 0,
      }
    },
    MuiMenuItem: {
      root: {
        '&.Mui-disabled': {
          '&:before': {
            backgroundColor: '#fff',
            opacity: .75
          }
        }
      },
    },
    MuiPaper: {
      root: {
        color: '#000'
      }
    },
    MuiDialog: {
      paper: {
        backgroundColor: '#fff',
        borderRadius: 24
      }
    },
    MuiDialogTitle: {
      root: {
        textAlign: 'center',
        color: '#000'
      }
    },
    MuiDialogContentText: {
      root: {
        color: '#000'
      }
    },
    MuiDialogActions: {
      root: {
        color: '#000',
        borderTop: '1px solid #C6C6C6',
        justifyContent: 'space-around'
      }
    },
    MuiPickersCalendarHeader: {
      iconButton: {
        color: 'inherit'
      },
      dayLabel: {
        color: 'inherit',
        textTransform: 'capitalize'
      },
      switchHeader: {
        textTransform: 'capitalize'
      }
    },
    MuiPickersMonthSelection: {
      container: {
        textTransform: 'capitalize'
      }
    },
    MuiPickersDay: {
      day: {
        color: 'inherit'
      }
    }
  }
});

export default theme;
