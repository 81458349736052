import React from 'react';

import {
  Box,
  Container,
  Typography
} from '@material-ui/core';

import {
  withStyles
} from '@material-ui/core/styles';

import {
  BrandBorder
} from '../../components';

const useStyles = theme => ({
  root: {
    position: 'relative',
    padding: '2rem 0',
    backgroundColor: theme.palette.background.light
  },
  isSmall: {
    padding: '1rem 0 !important',
  },
  isTextCenter: {
    textAlign: 'center'
  },
  isBig: {
    paddingTop: '4rem'
  },
  isSimple: {
    paddingTop: '2rem',
    borderBottom: `1px solid ${theme.palette.border}`
  },
  isSticky: {
    position: 'sticky',
    zIndex: 4,
    top: theme.mixins.toolbar.minHeight - 1,
    '@media (min-width:0px) and (orientation: landscape)': {
      top: theme.mixins.toolbar['@media (min-width:0px) and (orientation: landscape)'].minHeight - 1,
    },
    '@media (min-width:600px)': {
      top: theme.mixins.toolbar['@media (min-width:600px)'].minHeight - 1,
    }
  },
  minMargin: {
    marginBottom: 6
  },
  border: {
    position: 'absolute',
    bottom: 0,
    left: 0
  },
  logo: {
    height: 100,
    marginBottom: '1rem'
  }
});

export default withStyles(useStyles)(class PageHeader extends React.Component {
  getRootClassStyle  = () => {
    let rootClasses = this.props.classes.root;

    if (this.props.bigTitle) {
      rootClasses += ` ${this.props.classes.isBig}`;
    }

    if (this.props.simple) {
      rootClasses += ` ${this.props.classes.isSimple}`;
    }

    if (this.props.sticky) {
      rootClasses += ` ${this.props.classes.isSticky}`;
    }

    if (this.props.small) {
      rootClasses += ` ${this.props.classes.isSmall}`;
    }

    if (this.props.textCenter) {
      rootClasses += ` ${this.props.classes.isTextCenter}`;
    }

    return rootClasses;
  }

  render () {
    return (
      <Box
        component="div"
        className={ this.getRootClassStyle() }>
          <Container maxWidth="lg">
            {
              this.props.logo &&
                <div style={{ textAlign: 'center' }}>
                  <img src={ this.props.logo } alt="logo" className={ this.props.classes.logo } />
                </div>
            }

            {
              this.props.bigTitle &&
                <Box mb={1}>
                  <Typography
                    className={ this.props.classes.minMargin }
                    variant="h1"
                    component="h1">
                      { this.props.bigTitle }
                  </Typography>
                </Box>
            }

            {
              this.props.title &&
                <Typography
                  className={ this.props.classes.minMargin }
                  variant="h2"
                  component="h1">
                    { this.props.title }
                </Typography>
            }

            {
              this.props.smallTitle &&
                <Typography
                  className={ this.props.classes.minMargin }
                  variant="h3"
                  component="h1">
                    { this.props.smallTitle }
                </Typography>
            }

            {
              this.props.abstract &&
                <Typography
                  variant="subtitle1"
                  component="p">
                  { this.props.abstract }
                </Typography>
            }

            {
              this.props.text &&
                <Typography
                  variant="body1"
                  component="p">
                  { this.props.text }
                </Typography>
            }

            { this.props.children }
          </Container>

          {
            !this.props.simple &&
              <BrandBorder className={ this.props.classes.border } />
          }
      </Box>
    )
  }
})
