import CONFIG from '../../config.json';

import React from 'react';

import {
  Badge,
  Box,
  Button,
  Container,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  Typography
} from '@material-ui/core';

import {
  withStyles
} from '@material-ui/core/styles';

import {
  Link,
  withRouter
} from 'react-router-dom';

import {
  HeaderBar,
  PageHeader,
  Footer
} from '../../components';

import {
  province as provinceJSON,
  comuni as comuniJSON
} from '../../utils/json';

import {
  API
} from '../../utils';

import EvitaLaCodaLogo from '../../assets/images/logo-evitalacoda.png';

const StyledBadge = withStyles((theme) => ({
  badge: {
    right: -18,
    top: '50%',
    backgroundColor: theme.palette.common.white,
    fontWeight: 600
  },
}))(Badge);

const logos = {
  evitaLaCoda: EvitaLaCodaLogo
};


export default withRouter(class CustomerSearch extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      bookingCount: 0,
      province: '',
      city: '',
      cityList: []
    }
  }

  onProvinceSelectChange = (e) => {
    const province = e.target.value;
    const cityList = comuniJSON.filter(item => {
      return item.id_provincia === province.id;
    });

    this.setState({
      province,
      cityList,
      city: ''
    });
  }

  onCitySelectChange = (e) => {
    this.setState({ city: e.target.value });
  }

  onSearchButtonClick = () => {
    localStorage.setItem(`${CONFIG.app_prefix}_province`, this.state.province.id)
    localStorage.setItem(`${CONFIG.app_prefix}_city`, this.state.city.id)

    this.props.history.push(`/categories/${this.state.province.id}/${this.state.city.id}`);
  }

  componentDidMount () {
    API.getBooking()
      .then(response => {
        if (response.status === 200) {
          this.setState({
            bookingCount: response.data.data.length.toString()
          });
        }
      });

    const routeParams = this.props.match.params;
    const initProvince = localStorage.getItem(`${CONFIG.app_prefix}_province`) || routeParams.province || null;
    const initCity = localStorage.getItem(`${CONFIG.app_prefix}_city`) || routeParams.city || null;

    if (initProvince && initCity) {
      const province = provinceJSON.find(item => {
        return item.id === initProvince;
      });

      const cityList = comuniJSON.filter(item => {
        return item.id_provincia === initProvince;
      });

      const city = comuniJSON.find(item => {
        return item.id === initCity;
      });

      this.setState({
        province,
        cityList,
        city
      });
    }
  }

  render () {
    return (
      <React.Fragment>
        <HeaderBar />
        <PageHeader
          logo={ CONFIG.logo ? logos[CONFIG.logo] : null }
          title={ (() => {
            return (
              !CONFIG.logo
                ? (
                  <span style={{ fontSize: 46 }}>
                    {
                      CONFIG.composed_title.split('|').map((text, index) => {
                        return index % 2 === 0
                          ? <span key={ index }>{ text }</span>
                          : <span key={ index } style={{ fontWeight: 300 }}>{ text }</span>
                      })
                    }
                  </span>)
                : null
              )
          })()}>
          <div style={ CONFIG.logo ? { display: 'flex', justifyContent: 'center' } : {} }>
            <Button
              color="primary"
              component={ Link }
              style={{ marginLeft: -4 }}
              to="/profile">
                <StyledBadge
                  badgeContent={ this.state.bookingCount }
                  color="default">
                    Le tue prenotazioni
                </StyledBadge>
            </Button>
          </div>
        </PageHeader>
        <main>
          <Container maxWidth="lg">
            <Box mb={ 3 }>
              <Typography>Inserisci la tua posizione per prenotare la tua spesa o altri servizi essenziali</Typography>
            </Box>

            <Box mb={ 3 }>
              <Box mb={ 1 }>
                <FormControl variant="filled" fullWidth>
                  <InputLabel>Provincia</InputLabel>
                  <Select
                    value={ this.state.province }
                    onChange={ this.onProvinceSelectChange }
                    fullWidth>
                    <MenuItem disabled value="">Seleziona una provincia</MenuItem>
                    {
                      provinceJSON.map(item => {
                        return (
                          <MenuItem key={ item.id } value={ item }>{ item.nome }</MenuItem>
                        )
                      })
                    }
                  </Select>
                </FormControl>
              </Box>
              <Box mb={ 1 }>
                <FormControl variant="filled" fullWidth>
                  <InputLabel>Comune</InputLabel>
                  <Select
                    disabled={ this.state.province === '' }
                    value={ this.state.city }
                    onChange={ this.onCitySelectChange }
                    fullWidth>
                    <MenuItem disabled value="">Seleziona un comune</MenuItem>
                    {
                      this.state.cityList.map(item => {
                        return (
                          <MenuItem key={ item.id } value={ item }>{ item.nome }</MenuItem>
                        )
                      })
                    }
                  </Select>
                </FormControl>
              </Box>
              <Box>
                <Button
                  disabled={ this.state.city === '' }
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={ this.onSearchButtonClick }
                  fullWidth>
                    Trova attività
                </Button>
              </Box>
            </Box>
          </Container>
        </main>
        <Footer />
      </React.Fragment>
    )
  }
})
