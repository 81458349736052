import React from 'react';

import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@material-ui/core';

import {
  withStyles
} from '@material-ui/core/styles';

import {
  withRouter
} from 'react-router-dom';

import {
  API,
  EventEmitter
} from '../../utils';

const useStyles = theme => ({
  root: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: theme.palette.background.default,
    opacity: .9,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 9999
  }
})

export default withStyles(useStyles)(withRouter(class RestNotification extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      loader: false,
      error: false,
      errorMessage: 'Si è verificato un errore'
    }
  }

  onApiCallStart = () => {
    this.setState({
      loader: true,
      error: false
    });
  }

  onApiCallEnd = () => {
    this.setState({ loader: false });
  }

  onApiCallError = (error) => {
    let showError = false;
    let requireLogout = false;
    let errorMessage = 'Si è verificato un errore';

    if (error.response) {
      switch (error.response.status) {
        case 401:
        case 403:
        case 404:
        case 422:
        case 500:
          showError = true;
          break;
        default:
          showError= false;
      }

      if (error.response.status === 401) {
        requireLogout = true;
        errorMessage = 'Non sei autorizzato a visualizzare questa pagina. Si prega di effettuare il login.';
      } else if (error.response.status === 403) {
        errorMessage = 'Non hai l`autorizzazione per completare questa operazione.';
      } else if (error.response.status === 404) {
        errorMessage = 'La pagina che stai cercando di visualizzare non esiste.';
      } else if (error.response.status === 422) {
        errorMessage = 'Qualcosa è andato storto';

        if (error.response.data.errors) {
          if (error.response.data.errors.booking === 'user_max_bookings') {
            errorMessage = 'Hai raggiunto il massimo numero di prenotazioni.'
          }
          if (error.response.data.errors.booking === 'max_bookings_for_slot') {
            errorMessage = 'Hai raggiunto il massimo numero di prenotazioni per questa fascia oraria.'
          }
          if (error.response.data.errors.booking === 'user_max_bookings_for_slot') {
            errorMessage = 'Hai raggiunto il massimo numero di prenotazioni.'
          }
          if (error.response.data.errors.booking === 'not_available') {
            errorMessage = 'Prenotazione non disponibile'
          }
        }
      } else if (error.response.status === 500) {
        errorMessage = 'Qualcosa è andato storto';
      }
    } else {
      showError = true;
      errorMessage = 'C`è un problema di comunicazione con il server.'
    }

    if (requireLogout) {
      API.logout();
      this.props.history.push('/');
    }

    if (showError) {
      this.setState({
        error,
        errorMessage
      });
    }
  }

  onErrorDialogClose = () => {
    this.setState({
      error: false,
      errorMessage: 'Si è verificato un errore'
    });
  }

  componentDidMount () {
    EventEmitter.subscribe('apiCallStart', this.onApiCallStart);
    EventEmitter.subscribe('apiCallEnd', this.onApiCallEnd);
    EventEmitter.subscribe('apiCallError', this.onApiCallError);
  }

  render () {
    return (
      <React.Fragment>
        {
          this.state.loader &&
            <Box className={ this.props.classes.root }>
              <CircularProgress />
            </Box>
        }

        <Dialog open={ this.state.error !== false } onClose={ this.onErrorDialogClose }>
          <DialogTitle>Oops...</DialogTitle>
          <DialogContent>
            <DialogContentText>{ this.state.errorMessage }</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={ this.onErrorDialogClose }>Chiudi</Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}));
