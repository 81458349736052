import React from 'react';

import {
  Box,
  Button,
  Container,
  Typography
} from '@material-ui/core';

import {
  withStyles
} from '@material-ui/core/styles';

import * as moment from 'moment';

import {
  Link,
  withRouter
} from 'react-router-dom';

import {
  HeaderBar,
  PageHeader,
  SubPageHeader,
  Footer
} from '../../components';

moment.locale('it');

const useStyles = theme => ({
  capitalize: {
    textTransform: 'capitalize'
  }
})

export default withStyles(useStyles)(withRouter(class CompanyBookingResult extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      company: undefined,
      date: undefined,
      slot: undefined
    };

    this.companyId = this.props.match.params.id;
    this.date = this.props.match.params.date;
    this.slotId = this.props.match.params.slot;
    this.result = this.props.match.params.result;
  }

  componentDidMount () {
    if (this.props.location.state) {
      this.setState(this.props.location.state)
    }
  }

  render () {
    const company = this.state.company;
    const date = this.state.date;
    const slot = this.state.slot;

    return (
      <React.Fragment>
        <HeaderBar
          back={ `/company/${this.companyId}/${this.date}` }
          title="Orari"
        />

        <PageHeader
          sticky
          simple
          title={ company ? company.group.name : '' }
          text={ company ? company.address : '' }
        />

        {
          this.result === 'success' &&
            <SubPageHeader
              variant="success"
              text={ (() => {
                return (
                  <span>
                    <span>Hai prenotato il tuo accesso il { date ? moment(date).format('DD/MM') : '' }</span> <strong>dalle { slot ? slot.from.slice(0, 5) : '' } alle { slot ? slot.to.slice(0, 5) : '' }</strong>
                  </span>
                )
              })() }
            />
        }

        {
          this.result === 'error' &&
            <SubPageHeader
              variant="danger"
              text="C'e' stato un errore. Riprova"
            />
        }

        <main>
          <Container maxWidth="lg">
            {
              this.result === 'success' &&
                <React.Fragment>
                  <Box mt={3} mb={5}>
                    <Box mb={3}><Typography gutterBottom><strong>Cosa devo fare?</strong></Typography></Box>
                    <Box mb={3}><Typography gutterBottom>Evita di stare in coda.</Typography></Box>
                    <Box mb={3}><Typography gutterBottom>Non arrivare prima o dopo l'orario di prenotazione.</Typography></Box>
                    <Box mb={3}><Typography gutterBottom>Ci raccomandiamo di presentarsi all'ingresso all'interno dell'orario indicato.</Typography></Box>
                    <Box mb={3}><Typography gutterBottom>Dovravi mostrare un documento d'identità all'addetto all'ingresso.</Typography></Box>
                    <Box mb={3}><Typography gutterBottom>Non fermarti più del necessario e cerca di uscire poco frequentemente.</Typography></Box>
                    <Box mb={3}><Typography gutterBottom>Puoi trovare le tue prenotazioni nella sezione "Le tue prenotazioni". Ricorda di eliminarla se cambi programma, altri potrebbero averne bisogno.</Typography></Box>
                  </Box>

                  <Box mb={2} textAlign="center">
                    <Button
                      size="large"
                      variant="contained"
                      color="primary"
                      component={ Link }
                      to="/profile">
                        Le tue prenotazioni
                      </Button>
                  </Box>

                  <Box textAlign="center">
                    <Button component={ Link } to="/search">Torna alla home</Button>
                  </Box>
                </React.Fragment>
            }

            {
              this.result === 'error' &&
                <React.Fragment>
                  <Box mt={3} mb={5}>
                    <Box mb={3}><Typography gutterBottom><strong>Cosa devo fare?</strong></Typography></Box>
                    <Box mb={3}><Typography gutterBottom>I posti disponibili sono esauriti.</Typography></Box>
                    <Box mb={3}><Typography gutterBottom>Torna alla schermata iniziale e fai una nuova prenotazione.</Typography></Box>
                    <Box mb={3}><Typography gutterBottom>I nostri programmatori sono stati avvisati del problema.</Typography></Box>
                  </Box>

                  <Box textAlign="center">
                    <Button component={ Link } to="/search">Torna alla home</Button>
                  </Box>
                </React.Fragment>
            }
          </Container>
        </main>

        <Footer />
      </React.Fragment>
    )
  }
}))
