import React from 'react';

import {
  Container,
  TextField,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction
} from '@material-ui/core';

import {
  withStyles
} from '@material-ui/core/styles';

import {
  ArrowForwardIos as ArrowNextIcon
} from '@material-ui/icons';

import {
  Link,
  withRouter
} from 'react-router-dom';

import * as moment from 'moment';

import {
  HeaderBar,
  PageHeader,
  Footer
} from '../../components';

import {
  API
} from '../../utils';

moment.locale('it');

const useStyles = theme => ({
  listArrow: {
    display: 'flex',
    alignItems: 'center'
  },
})

export default withStyles(useStyles)(withRouter(class OperatorCompanyList extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      list: undefined,
      filteredList: undefined
    };

    this.today = moment().format('YYYY-MM-DD');
  }

  onFilterInputChange = (e) => {
    let filteredList = [];

    if (e.target.value.length > 0) {
      filteredList = this.state.list.filter(item => item.name.toLowerCase().includes(e.target.value.toLowerCase()));
    } else {
      filteredList = this.state.list;
    }

    this.setState({ filteredList });

    window.scrollTo(0, 0);
  }

  componentDidMount () {
    const categoryId = parseInt(this.props.match.params.category);

    API.companies(categoryId)
      .then(response => {
        if (response.status === 200) {
          this.setState({
            list: response.data.data,
            filteredList: response.data.data
          })
        }
      });
  }

  render () {
    return (
      <React.Fragment>
        <HeaderBar
          back={ `/operator/categories` }
          title="Attività"
        />

        <PageHeader sticky simple>
          <TextField
            fullWidth
            type="text"
            size="medium"
            variant="filled"
            label="Filtra per nome"
            onChange={ this.onFilterInputChange }
          />
        </PageHeader>

        <main>
          <Container maxWidth="lg">
            {
              (this.state.filteredList && this.state.filteredList.length > 0) &&
                <List component="nav" aria-label="activity type list">
                {
                  this.state.filteredList.map(item => {
                    return (
                      <ListItem
                        divider
                        key={ item.id }
                        button
                        component={ Link }
                        to={ `/operator/company/${item.id}/${this.today}` }
                        >
                          <ListItemText
                            primaryTypographyProps={{ variant: 'h2', style:{ marginBottom: 6 } }}
                            primary={ item.group.name }
                            secondary={ `${item.address}, ${item.city}` }
                            secondaryTypographyProps={{ variant: 'subtitle2' }} />
                          <ListItemSecondaryAction
                            edge="end"
                            className={ this.props.classes.listArrow }>
                              <ArrowNextIcon />
                          </ListItemSecondaryAction>
                      </ListItem>
                    )
                  })
                }
                </List>
            }

            {
              (this.state.filteredList && this.state.filteredList.length === 0) &&
                <Typography>Non ci sono risulati</Typography>
            }

          </Container>
        </main>

        <Footer />
      </React.Fragment>
    )
  }
}))
