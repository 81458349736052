import React from 'react';

import {
  Box,
  Container,
  Link as Anchor
} from '@material-ui/core';

import {
  withRouter,
  Link
} from 'react-router-dom';

import {
  withStyles
} from '@material-ui/core/styles';

const useStyles = theme => ({
  root: {
    padding: '1.5rem 0 1rem',
    backgroundColor: theme.palette.background.light,
    borderTop: `1px solid ${theme.palette.border}`,
    fontWeight: 500
  }
});

export default withStyles(useStyles)(withRouter(class Footer extends React.Component {
  render () {
    return (
      <Box
        component="footer"
        className={ this.props.classes.root }>
          <Container maxWidth="lg">
            {/*<Box mb={1}>
              <Anchor
                component={ Link }
                to="/domande-frequenti">
                  Domande frequenti
              </Anchor>
            </Box> */}
            <Box mb={1}>
              <Anchor
                component={ Link }
                to="/chi-siamo">
                  Chi siamo
              </Anchor>
            </Box>
            <Box mb={1}>
              <Anchor
                component={ Link }
                to="/gdpr">
                  Privacy Policy
              </Anchor>
            </Box>
          </Container>
      </Box>
    )
  }
}))
