import React from 'react';

import {
  Box,
  ButtonBase,
  Button,
  Container,
  Typography,
  TextField,
  Link as Anchor,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Switch
} from '@material-ui/core';

import {
  AddCircle as AddIcon
} from '@material-ui/icons';

import {
  withStyles
} from '@material-ui/core/styles';

import {
  Link,
  withRouter
} from 'react-router-dom';

import * as moment from 'moment';

import {
  HeaderBar,
  PageHeader,
  TrafficLight
} from '../../components';

import {
  API
} from '../../utils';

moment.locale('it');

const useStyles = theme => ({
  container: {
    position: 'relative',
    flex: 1,
    display: 'flex'
  },
  scrollContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    padding: 0,
    overflowY: 'auto',
    overflowX: 'hidden',
    overflowScrolling: 'touch'
  },
  header: {
    padding: '2rem 0',
    marginBottom: '2rem',
    backgroundColor: theme.palette.background.light,
    borderBottom: `1px solid ${theme.palette.border}`
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  headerCounters: {
    display: 'flex',
    alignItems: 'flex-end'
  },
  headerAddButton: {
    width: 120,
    color: '#fff',
    textAlign: 'center'
  },
  headerAddButtonIcon: {
    width: 60,
    height: 60
  },
  headerAddButtonText: {
    fontSize: '.9rem'
  },
  footer: {
    padding: '1.5rem 0 1rem',
    backgroundColor: theme.palette.background.light,
    borderTop: `1px solid ${theme.palette.border}`
  },
  footerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  showOnMobile: {
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    }
  },
  hideOnMobile: {
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  },
  listItem:{
    minHeight: 80
  },
  listItemSecondaryAction: {
    right: theme.mixins.gutters
  },
  switch: {
    transform: 'scale(1.4)',
    transformOrigin: 'right center'
  }
});

export default withStyles(useStyles)(withRouter(class OperatorCompanySlot extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      company: undefined,
      slot: undefined,
      users: undefined,
      filteredUsers: undefined,
      total: 0,
      not_booked: 0
    };

    this.companyId = this.props.match.params.id;
    this.date = this.props.match.params.date;
    this.slotId = this.props.match.params.slot;

    this.refreshThread = null;
  }

  getPrenotationStatus = () => {
    // NB: success status rate is 30% of 'actual_capacity'
    const { slot } = this.state;
    const thirthyPerc = Math.round(slot.actual_capacity * 30 / 100);

    if (this.state.total <= thirthyPerc) {
      return 'success';
    } else if (this.state.total > thirthyPerc && this.state.total < slot.actual_capacity) {
      return 'warning';
    } else {
      return 'danger';
    }
  }

  getPageTitle = () => {
    let title = '';

    if (this.state.slot) {
      title += `${this.state.slot.from.slice(0, 5)} - ${this.state.slot.to.slice(0, 5)}`;
    }

    if (this.state.company) {
      title += ` • ${ this.state.company.group.name}`;
      title += ` • ${ this.state.company.address}`;
    }

    return title;
  }

  onFilterInputChange = (e) => {
    let filteredUsers = [];

    if (e.target.value.length > 0) {
      filteredUsers = this.state.users.filter(item => {
        let find = false;

        if (!find && item.name) {
          find = item.name.toLowerCase().includes(e.target.value.toLowerCase());
        }

        if (!find && item.lastname) {
          find = item.lastname.toLowerCase().includes(e.target.value.toLowerCase());
        }

        if (!find && item.code) {
          find = item.code.toLowerCase().includes(e.target.value.toLowerCase());
        }

        return find;
      });
    } else {
      filteredUsers = this.state.users;
    }

    this.setState({ filteredUsers });
  }

  onAddNoPrenotationButtonClick = () => {
    this.clearRefreshThread();

    API.addBookingWithoutPrenotation(this.slotId, this.date)
      .then(response => {
        if (response.status === 200) {
          this.setState({
            total: response.data.total,
            not_booked: response.data.not_booked
          });

          this.initRefreshThread();
        }
      });
  }

  onUserSwitch = (e, user) => {
    const checked = e.target.checked;

    this.clearRefreshThread();

    API.updateBooking(user.id, checked)
      .then(response => {
        if (response.status === 200) {
          const data = response.data.booking_time_slot;

          const updatedUsers = this.state.users.map(item => {
            if (item.id === user.id) {
              item.used = checked
            }

            return item;
          });

          this.setState({
            company: data.company_time_slot.company,
            slot: data.company_time_slot,
            users: updatedUsers,
            filteredUsers: updatedUsers
          });

          this.initRefreshThread();
        }
      });
  }

  clearRefreshThread = () => {
    clearTimeout(this.refreshThread);
    this.refreshThread = null;
  }

  initRefreshThread = () => {
    this.refreshThread = setTimeout(() => {
      API.getCompanyBookingThread(this.slotId, this.date, 1, 9999)
        .then(response => {
          if (response.status === 200 || response.status === 201) {
            this.setState({
              users: response.data.booking_users.data,
              total: response.data.total,
              not_booked: response.data.not_booked
            });
          }
        });

      if (this.refreshThread) {
        this.initRefreshThread();
      }
    }, 10000);
  }

  componentDidMount () {
    API.getCompanyBooking(this.slotId, this.date, 1, 9999)
      .then(response => {
        if (response.status === 200 || response.status === 201) {
          this.setState({
            company: response.data.companyTimeSlot.company,
            slot: response.data.companyTimeSlot,
            users: response.data.booking_users.data,
            filteredUsers: response.data.booking_users.data,
            total: response.data.total,
            not_booked: response.data.not_booked
          });

          this.initRefreshThread();
        }
      });
  }

  componentWillUnmount () {
    this.clearRefreshThread();
  }

  render () {
    return (
      <React.Fragment>
        <HeaderBar
          back={ `/operator/company/${this.companyId}/${this.date}` }
          title="Orari"
        />

        <PageHeader
          simple
          small
          sticky
          textCenter
          text={ this.getPageTitle() }
        />

        <main style={{ padding: 0 }}>
          <Box component="div" className={ this.props.classes.container }>
            <Box py={4} component="div" className={ this.props.classes.scrollContainer }>
              <Box component="header" className={ this.props.classes.header }>
                <Container maxWidth="lg">
                  <Box className={ this.props.classes.headerContainer }>
                    <Box>
                      {
                        this.state.slot &&
                          <TrafficLight
                            variant={ this.getPrenotationStatus() }
                            size="large"
                          />
                      }
                    </Box>

                    <Box px={2} flex="1">
                      <Box mb={1} className={ this.props.classes.headerCounters }>
                        <Box>
                          <Typography variant="h6">Occupati</Typography>
                          <Typography variant="h1">{ this.state.total }</Typography>
                        </Box>

                        <Box px={2}>
                          <Typography variant="h1">/</Typography>
                        </Box>

                        <Box>
                          <Typography variant="h6">Massimo</Typography>
                          <Typography variant="h1">{ this.state.slot ? this.state.slot.actual_capacity : '0' }</Typography>
                        </Box>
                      </Box>

                      <Typography
                        variant="subtitle2"
                        className={ this.props.classes.hideOnMobile }>
                          Di cui { this.state.total - this.state.not_booked } prenotazioni da app
                      </Typography>
                    </Box>

                    {
                      this.state.slot && this.state.total < this.state.slot.actual_capacity &&
                        <Box pl={2} borderLeft="1px solid #ccc" className={ this.props.classes.hideOnMobile }>
                          <ButtonBase onClick={ this.onAddNoPrenotationButtonClick }>
                            <Box p={1} className={ this.props.classes.headerAddButton }>
                              <AddIcon className={ this.props.classes.headerAddButtonIcon } />
                              <Typography className={ this.props.classes.headerAddButtonText }>Ingresso senza prenotazione</Typography>
                            </Box>
                          </ButtonBase>
                        </Box>
                    }
                  </Box>

                  {
                    this.state.slot && this.state.total < this.state.slot.actual_capacity &&
                      <Box pt={2} textAlign="center" className={ this.props.classes.showOnMobile }>
                        <Button
                          size="small"
                          variant="contained"
                          color="primary"
                          startIcon={ <AddIcon /> }
                          onClick={ this.onAddNoPrenotationButtonClick }
                        >
                          Ingresso senza prenotazione
                        </Button>
                      </Box>
                  }
                </Container>
              </Box>
              <Container maxWidth="lg">
                {
                  (this.state.users && this.state.users.length > 0) &&
                    <Box mb={2}>
                      <TextField
                        fullWidth
                        type="text"
                        size="medium"
                        variant="filled"
                        label="Cerca una persona prenotata"
                        onChange={ this.onFilterInputChange }
                      />
                    </Box>
                }

                {
                  (this.state.filteredUsers && this.state.filteredUsers.length > 0) &&
                    <React.Fragment>
                      <List>
                        {
                          this.state.filteredUsers.map(item => {
                            return (
                              <ListItem
                                className={ this.props.classes.listItem }
                                key={ item.id }
                                divider
                              >
                                <ListItemText
                                  primary={ (item.name && item.lastname) ? `${item.name} ${item.lastname}` : item.code }
                                  secondary={
                                    <Typography>
                                      {
                                        item.birthday &&
                                          <span>{ moment(item.birthday).format('DD/MM/YYYY') }<br/></span>
                                      }
                                      {
                                        (item.name && item.lastname) &&
                                          <span>{ item.code }</span>
                                      }
                                    </Typography>
                                  }
                                />
                                <ListItemSecondaryAction className={ this.props.classes.listItemSecondaryAction }>
                                  <Switch
                                    className={ this.props.classes.switch }
                                    color="primary"
                                    edge="end"
                                    onChange={ (e) => this.onUserSwitch(e, item) }
                                    checked={ item.used }
                                  />
                                </ListItemSecondaryAction>
                              </ListItem>
                            )
                          })
                        }
                      </List>
                    </React.Fragment>
                }

                {
                  (this.state.filteredUsers && this.state.filteredUsers.length === 0) &&
                    <Typography>Non ci sono prenotazioni</Typography>
                }
              </Container>
            </Box>
          </Box>
        </main>

        <Box component="footer" className={ this.props.classes.footer }>
          <Container maxWidth="lg" className={ this.props.classes.footerContainer }>
            <Box>
              {/*<Box mb={1}>
                <Anchor component={ Link } to="/domande-frequenti">Domande frequenti</Anchor>
              </Box> */}
              <Box mb={1}>
                <Anchor component={ Link } to="/chi-siamo">Chi siamo</Anchor>
              </Box>
              <Box mb={1}>
                <Anchor component={ Link } to="/gdpr">Privacy Policy</Anchor>
              </Box>
            </Box>

            <Button
              size="small"
              variant="contained"
              color="primary"
              component={ Link }
              to={ `/operator/booking/company/${this.companyId}` }
            >
              Nuova prenotazione
            </Button>
          </Container>
        </Box>
      </React.Fragment>
    )
  }
}))
