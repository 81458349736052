import React from 'react';

import {
  Box,
  Container,
  Typography
} from '@material-ui/core';

import {
  withStyles,
  withTheme
} from '@material-ui/core/styles';

const useStyles = theme => ({
  root: {
    padding: '1rem 0',
    borderBottom: `1px solid ${theme.palette.border}`,
    boxShadow: `0 0 5px ${theme.palette.border}`
  }
})

export default withTheme(withStyles(useStyles)(class SubPageHeader extends React.Component {
  getTextColor = () => {
    let color;

    switch (this.props.variant) {
      case 'success':
        color = this.props.theme.palette.success.main;
        break;
      case 'warning':
        color = this.props.theme.palette.warning.main;
        break;
      case 'danger':
        color = this.props.theme.palette.error.main;
        break;
      default:
        color = this.props.variant || this.props.theme.typography.body1.color;
    }

    return color;
  }

  render () {
    return (
      <Box
        color={ this.getTextColor() }
        className={ this.props.classes.root }>
          <Container maxWidth={ this.props.maxWidth || 'sm' }>
            {
              this.props.text &&
                <Typography variant="h5">
                  { this.props.text }
                </Typography>
            }

            {
              this.props.smallText &&
                <Typography variant="h6">
                  { this.props.smallText }
                </Typography>
            }
          </Container>
      </Box>
    )
  }
}))
