import CONFIG from '../../config.json';

import React from 'react';

import {
  Box,
  Button,
  Container,
  TextField,
  Typography
} from '@material-ui/core';

import {
  withRouter
} from 'react-router-dom';

import {
  PageHeader,
  Footer
} from '../../components';

import {
  API
} from '../../utils';

export default withRouter(class OperatorLogin extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      username: '',
      password: '',
      validate: true
    };
  }

  onUsernameChange = (e) => {
    this.setState({
      username: e.target.value
    });
  }

  onPasswordChange = (e) => {
    this.setState({
      password: e.target.value
    });
  }

  onLoginButtonClick = () => {
    API.login('password', CONFIG.client_id, CONFIG.client_secret, this.state.username, this.state.password)
      .then(response => {
        if (response.status === 200) {
          API.categories()
            .then(subResponse => {
              if (subResponse.status === 200) {
                const categories = subResponse.data.data;
                let redirectPath = '/operator/categories';

                if (categories.length === 1) {
                  redirectPath += `/${categories[0].id}`;
                }

                this.props.history.push(redirectPath);
              }
            });
        }
      })
      .catch(error => {
        if (error.response && error.response.status === 400) {
          this.setState({ validate: false });
        }
      });
  }

  componentDidMount () {
    const apiToken = localStorage.getItem(`${CONFIG.app_prefix}_token`);
    const userRole = localStorage.getItem(`${CONFIG.app_prefix}_role`);

    if (apiToken && userRole === 'operator') {
      this.props.history.push('/operator/categories');
    }
  }

  render () {
    return (
      <React.Fragment>
        <PageHeader bigTitle="Accedi" />

        <main>
          <Container maxWidth="lg">
            <Box mb={ 3 }>
              <Typography>Accedi come operatore</Typography>
            </Box>

            <Box mb={ 1 }>
              <TextField
                error={ !this.state.validate }
                autoComplete="off"
                fullWidth
                label="Username o Email"
                variant="filled"
                value={ this.state.username }
                onChange={ this.onUsernameChange }>
              </TextField>
            </Box>

            <Box mb={ 1 }>
              <TextField
                type="password"
                error={ !this.state.validate }
                autoComplete="off"
                fullWidth
                label="Password"
                variant="filled"
                value={ this.state.password }
                onChange={ this.onPasswordChange }>
              </TextField>
            </Box>

            <Box mb={ 3 }>
              <Button
                disabled={ this.state.username.length === 0 || this.state.password.length === 0 }
                variant="contained"
                color="primary"
                size="large"
                onClick={ this.onLoginButtonClick }
                fullWidth>
                  Accedi
              </Button>
            </Box>

            {
              !this.state.validate &&
                <Box my={ 2 }>
                  <Typography color="error" variant="caption">Username e/o password non validi. Si prega di riprovare</Typography>
                </Box>
            }
          </Container>
        </main>

        <Footer />
      </React.Fragment>
    )
  }
})
