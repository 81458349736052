import CONFIG from '../../config.json';

import React from 'react';

import {
  AppBar,
  Toolbar,
  IconButton,
  Button,
  Box,
  Typography
} from '@material-ui/core';

import {
  withStyles
} from '@material-ui/core/styles';

import {
  ArrowBackIos as BackIcon
} from '@material-ui/icons';

import {
  Link,
  withRouter
} from 'react-router-dom';

import {
  API
} from '../../utils';

const useStyles = theme => ({
  header: theme.mixins.toolbar,
  title: {
    fontSize: '1rem',
    fontWeight: 700,
    display: 'inline-block',
    verticalAlign: 'middle',
    marginLeft: 4
  },
  backButton: {
    padding: 0,
    width: 38,
    height: 38
  },
  backButtonIcon: {
    marginRight: -6
  },
  appBar: {
    backgroundColor: theme.palette.background.default
  },
  toolbar: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  toolbarBoxLeft: {
    flex: 1,
    textAlign: 'left'
  },
  toolbarBoxRight: {
    flex: 1,
    textAlign: 'right'
  }
})

export default withStyles(useStyles)(withRouter(class HeaderBar extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      auth: localStorage.getItem(`${CONFIG.app_prefix}_token`)
    };
  }

  onLogoutButtonClick = () => {
    API.logout();
    this.props.history.push('/');
  }

  render () {
    return (
        <Box className={ this.props.classes.header }>
          <AppBar
            position="fixed"
            className={ this.props.classes.appBar }>
              <Toolbar className={ this.props.classes.toolbar }>
                <Box className={ this.props.classes.toolbarBoxLeft }>
                  {
                    this.props.back &&
                      <IconButton
                        edge="start"
                        size="medium"
                        className={ this.props.classes.backButton }
                        aria-label="back"
                        component={ Link }
                        to={ this.props.back }>
                          <BackIcon
                            fontSize="small"
                            className={ this.props.classes.backButtonIcon }
                          />
                      </IconButton>
                  }

                  {
                    this.props.historyBack &&
                      <IconButton
                        edge="start"
                        size="medium"
                        className={ this.props.classes.backButton }
                        aria-label="back"
                        onClick={ this.props.history.goBack }>
                          <BackIcon
                            fontSize="small"
                            className={ this.props.classes.backButtonIcon }
                          />
                      </IconButton>
                  }

                  {
                    this.props.title &&
                      <Typography
                        className={ this.props.classes.title }>
                          { this.props.title }
                      </Typography>
                  }
                </Box>

                <Box className={ this.props.classes.toolbarBoxRight }>
                  {
                    this.state.auth &&
                      <Button
                        onClick={ this.onLogoutButtonClick }>
                          Esci
                      </Button>
                  }
                </Box>
              </Toolbar>
          </AppBar>
        </Box>
    )
  }
}))
