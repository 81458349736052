import React from 'react';

import {
  Box,
  Button,
  Container,
  Typography
} from '@material-ui/core';

import {
  Link,
  withRouter
} from 'react-router-dom';

import {
  HeaderBar,
  PageHeader,
  Footer
} from '../../components';

import {
  API
} from '../../utils';

export default withRouter(class OperatorCompanySlotInstructions extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      company: undefined
    };

    this.companyId = this.props.match.params.id;
    this.date = this.props.match.params.date;
    this.slotId = this.props.match.params.slot;
  }

  componentDidMount () {
    API.company(this.companyId)
      .then(response => {
        if (response.status === 200) {
          this.setState({
            company: response.data
          })
        }
      });
  }

  render () {
    return (
      <React.Fragment>
        <HeaderBar
          back={ `/operator/company/${this.companyId}/${this.date}` }
          title="Orari"
        />

        <PageHeader
          maxWidth="lg"
          sticky
          simple
          title={ this.state.company ? this.state.company.group.name : '' }
          text={ this.state.company ? this.state.company.address : '' }
        />

        <main>
          <Container maxWidth="lg">
            <Box mb={3}>
              <Typography variant="h4">Cosa devo fare?</Typography>
            </Box>

            <Box mb={5}>
              <Box mb={3}>
                {
                  (this.state.company && this.state.company.require_user_info === 1) &&
                    <Typography gutterBottom>Quando una persona dichiara di aver prenotato via app, chiedile un documento e spunta il suo nome dalla lista.</Typography>
                }
                {
                  (this.state.company && this.state.company.require_user_info !== 1) &&
                    <Typography gutterBottom>Quando una persona dichiara di aver prenotato via app, chiedile il codice di prenotazione e spunta quest'ultimo dalla lista.</Typography>
                }
              </Box>
              <Box mb={3}><Typography gutterBottom>Se una persona non ha prenotato da app, la raccomandazione è di creare per lei una nuova prenotazione in una fascia disponibile di sua preferenza e chiederle di tornare in quella fascia. Non è possibile prenotare per la fascia oraria attuale.</Typography></Box>
              <Box mb={3}><Typography gutterBottom>Il 10% degli ingressi non può essere prenotato da app, quindi rimangono sempre dei posti disponibili per gli ingressi senza prenotazione.</Typography></Box>
              <Box mb={3}><Typography gutterBottom style={{ textDecoration: 'underline' }}>Sono pensati in particolar modo per anziani e portatori di handicap.</Typography></Box>
              <Box mb={3}><Typography gutterBottom>Tocca il pulsante (+) ad ogni ingresso senza prenotazione per tenere il conto dei posti occupati nella fascia oraria. Non superare il numero massimo.</Typography></Box>
              <Box mb={3}><Typography gutterBottom>Evita il più possibile il formarsi di code e ricorda di rispettare le distanze.</Typography></Box>
              <Box mb={3}><Typography gutterBottom>La preoccupazione può portare le persone ad essere scortesi e maleducate.</Typography></Box>
              <Box mb={3}><Typography gutterBottom>Sii sempre gentile e comprensivo.</Typography></Box>
            </Box>

            <Box mb={3} textAlign="center">
              <Button
                size="large"
                variant="contained"
                color="primary"
                component={ Link }
                to={ `/operator/company/${this.companyId}/${this.date}/${this.slotId}` }>
                  Ho Capito
              </Button>
            </Box>
          </Container>
        </main>

        <Footer />
      </React.Fragment>
    )
  }
})
