import React from 'react';

import {
  Box,
  Button,
  Container,
  TextField,
  Typography
} from '@material-ui/core';

import {
  withStyles
} from '@material-ui/core/styles';

import * as moment from 'moment';

import MomentUtils from "@date-io/moment";

import {
  DatePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers';

import {
  Link,
  withRouter
} from 'react-router-dom';

import {
  HeaderBar,
  PageHeader,
  SubPageHeader,
  Footer,
  TrafficLight
} from '../../components';

import {
  API
} from '../../utils';

// TODO: enable captcha
// import CONFIG from '../../config.json';

moment.locale('it');

const useStyles = theme => ({
  capitalize: {
    textTransform: 'capitalize'
  }
})

export default withStyles(useStyles)(withRouter(class OperatorBookingCompanyCreate extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      date: undefined,
      company: undefined,
      slot: undefined,
      firstname: '',
      lastname: '',
      birthday: null,
      captcha: true
    }

    this.companyId = this.props.match.params.id;
    this.date = this.props.match.params.date;
    this.slotId = this.props.match.params.slot;
  }

  onFirstnameChange = (e) => {
    this.setState({ firstname: e.target.value });
  }

  onLastnameChange = (e) => {
    this.setState({ lastname: e.target.value });
  }

  onBirthdayChange = (birthday) => {
    this.setState({ birthday });
  }

  onBookingButtonClick = () => {
    if (this.isFormValid()) {
      API.createBooking(
        this.state.slot.id,
        this.state.date,
        this.state.firstname,
        this.state.lastname,
        this.state.birthday.format('YYYY-MM-DD')
      ).then(response => {
        let pathname = `/operator/booking/company/${this.companyId}/${this.date}/${this.slotId}/create/`;

        pathname += response.status === 201 ? 'success' : 'error';

        this.props.history.push({
          pathname,
          state: {
            date: this.state.date,
            company: this.state.company,
            slot: this.state.slot
          }
        });
      });
    }
  }

  onFastBookingButtonClick = () => {
    API.createBooking(
      this.state.slot.id,
      this.state.date
    ).then(response => {
      let pathname = `/operator/booking/company/${this.companyId}/${this.date}/${this.slotId}/create/`;

      pathname += response.status === 201 ? 'success' : 'error';

      this.props.history.push({
        pathname,
        state: {
          date: this.state.date,
          company: this.state.company,
          slot: this.state.slot
        }
      });
    });
  }

  isFormValid = () => {
    return this.state.firstname.length > 0
      && this.state.lastname.length > 0
      && (this.state.birthday && this.state.birthday.format().length > 0)
      && this.state.captcha
  }

  isSlotFull () {
    const slot = this.state.slot;
    return slot && slot.actual_capacity === slot.booking_count;
  }

  isSlotwWarning () {
    const slot = this.state.slot;
    return slot && (slot.booking_count > (slot.actual_capacity / 2));
  }

  componentDidMount () {
    API.slots(this.companyId, this.date)
      .then(response => {
        if (response.status === 200) {
          this.setState({
            company: response.data.company,
            date: response.data.date,
            slot: response.data.slots.find(item => {
              return item.id === parseInt(this.slotId)
            })
          });
        }
      });
  }

  render () {
    const company = this.state.company;
    const date = this.state.date;
    const slot = this.state.slot;

    return (
      <React.Fragment>
        <HeaderBar
          historyBack={ true }
          title="Indietro"
        />

        <PageHeader
          maxWidth="lg"
          sticky
          simple
          title={ company ? company.group.name : '' }
          text={ company ? company.address : '' }
        />

        {
          this.isSlotFull() &&
            <SubPageHeader
              variant="danger"
              text="Posti disponibili esauriti"
            />
        }

        <main>
          <Container maxWidth="lg">
            {
              !this.isSlotFull() &&
                <React.Fragment>
                  <Box mb={3}>
                    <Box mb={1}>
                      <Typography
                        variant="h4"
                        component="h2">
                          Prenota un accesso per terzi il { date ? moment(date).format('DD/MM') : '' } <strong>{  slot ? `dalle ${slot.from.slice(0, 5)} alle ${slot.to.slice(0, 5)}` : ''}</strong>
                      </Typography>
                    </Box>

                    {
                      this.isSlotwWarning() &&
                        <TrafficLight
                          variant="warning"
                          text="Pochi posti disponibili" />
                    }
                  </Box>

                  {
                    (this.state.company && this.state.company.require_user_info === 1) &&
                      <Box>
                        <Box mb={2}>
                          <TextField
                            label="Nome"
                            type="text"
                            value={ this.state.firstname }
                            variant="filled"
                            fullWidth
                            onChange={ this.onFirstnameChange }
                          />
                        </Box>

                        <Box mb={2}>
                          <TextField
                            label="Cognome"
                            type="text"
                            value={ this.state.lastname }
                            variant="filled"
                            fullWidth
                            onChange={ this.onLastnameChange }
                          />
                        </Box>

                        <Box mb={2}>
                          <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale="it">
                            <DatePicker
                              fullWidth
                              allowKeyboardControl={ false }
                              autoOk={ true }
                              cancelLabel="Annulla"
                              disableFuture
                              disableToolbar
                              openTo="year"
                              inputVariant="filled"
                              format="DD/MM/YYYY"
                              label="Data di nascita"
                              views={["year", "month", "date"]}
                              value={ this.state.birthday }
                              onChange={ this.onBirthdayChange }
                            />
                          </MuiPickersUtilsProvider>
                        </Box>

                        <Button
                          size="large"
                          variant="contained"
                          color="primary"
                          fullWidth
                          onClick={ this.onBookingButtonClick }
                          disabled={ !this.isFormValid() }
                        >
                          Prenota
                        </Button>
                      </Box>
                  }

                  {
                    (this.state.company && this.state.company.require_user_info !== 1) &&
                      <Button
                        size="large"
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={ this.onFastBookingButtonClick }
                      >
                        Prenota
                      </Button>
                  }
                </React.Fragment>
            }

            {
              this.isSlotFull() &&
                <React.Fragment>
                  <Box mt={3} mb={5}>
                    <Box mb={3}><Typography gutterBottom><strong>Cosa devo fare?</strong></Typography></Box>
                    <Box mb={3}><Typography gutterBottom>I posti disponibili sono esauriti.</Typography></Box>
                    <Box mb={3}><Typography gutterBottom>Torna alla schermata iniziale e fai una nuova prenotazione.</Typography></Box>
                  </Box>

                  <Box textAlign="center">
                    <Button component={ Link } to="/operator/categories">Torna alla home</Button>
                  </Box>
                </React.Fragment>
            }
          </Container>
        </main>

        <Footer />
      </React.Fragment>
    )
  }
}))
