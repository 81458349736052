import React from 'react';

import {
  Box,
  Typography
} from '@material-ui/core';

import {
  withStyles,
  withTheme
} from '@material-ui/core/styles';

const useStyles = theme => ({
  root: {
    display: 'flex',
    alignItems: 'center'
  },
  bullet: {
    width: 16,
    height: 16,
    marginRight: 8,
    borderRadius: '100%',
    display: 'inline-block'
  },
  bulletLarge: {
    width: 26,
    height: 26
  },
  text: {
    opacity: .35
  }
});

export default withTheme(withStyles(useStyles)(class TrafficLight extends React.Component {
  getBulletColor = () => {
    let color;

    switch (this.props.variant) {
      case 'success':
        color = this.props.theme.palette.success.main;
        break;
      case 'warning':
        color = this.props.theme.palette.warning.main;
        break;
      case 'danger':
        color = this.props.theme.palette.error.main;
        break;
      default:
        color = this.props.variant || this.props.theme.palette.common.white;
    }

    return color;
  }

  render () {
    return (
      <Box component="span" className={ this.props.classes.root }>
        <Box
          className={ `${this.props.classes.bullet} ${this.props.size === 'large' ? this.props.classes.bulletLarge : ''}` }
          style={{ backgroundColor: this.getBulletColor() }}>
        </Box>

        {
          this.props.text &&
            <Typography className={ this.props.classes.text }>
              { this.props.text }
            </Typography>
        }
      </Box>
    )
  }
}))
