import CONFIG from '../config.json';

import axios from 'axios';

import EventEmitter from './EventEmitter';

const perform = async (url, params, method = 'GET', preventDispatchStart) => {
  if (!preventDispatchStart) {
    EventEmitter.dispatch('apiCallStart');
  }

  try {
    switch (method) {
      case 'POST':
        return await rest.post(url, params);
      case 'PUT':
        return await rest.put(url, params);
      case 'PATCH':
        return await rest.patch(url, params);
      case 'DELETE':
        return await rest.delete(url, { params });
      default:
        return await rest.get(url, { params });
    }
  } catch (error) {
    EventEmitter.dispatch('apiCallError', error);
    return error;
  } finally {
    EventEmitter.dispatch('apiCallEnd');
  }
};

/*
 * Register via mobile OTP
 *
 * PARAMS:
 * mobile: string/numeric|min:10|required
 */
const register = async (mobile) => {
  return perform('/user/register/mobile', { mobile }, 'POST');
};

/*
 * Login OAuth2
 *
 * PARAMS:
 * grant_type: password
 * client_id: <id client fronted>
 * client_secret: <password client fronted>
 * username: <mobile number>
 * password: <OTP code></OTP>
 */
const login = async (grant_type, client_id, client_secret, username, password) => {
  const request = perform('/oauth/token', {
    grant_type,
    client_id,
    client_secret,
    username,
    password
  }, 'POST');

  return new Promise((resolve, reject) => {
    request
      .then(response => {
        if (!(response instanceof Error)) {
          if (response.status === 200 && response.data) {
            const token = response.data.access_token;

            rest.defaults.headers.common['Authorization'] = 'Bearer ' + token;

            localStorage.setItem(`${CONFIG.app_prefix}_token`, token);

            user()
              .then(response => {
                if (response.status === 200) {
                  const role = response.data.role.name.toLowerCase();

                  localStorage.setItem(`${CONFIG.app_prefix}_role`, role);
                  resolve(response);
                } else {
                  reject(response);
                }
              })
              .catch(error => {
                console.log(error);
                reject(response);
              });
          }
        } else {
          reject(response);
        }
      })
      .catch(error => {
        reject(error);
      })
  });
};

const logout = () => {
  localStorage.removeItem(`${CONFIG.app_prefix}_token`);
  localStorage.removeItem(`${CONFIG.app_prefix}_role`);
  localStorage.removeItem(`${CONFIG.app_prefix}_province`);
  localStorage.removeItem(`${CONFIG.app_prefix}_city`);
  localStorage.removeItem(`${CONFIG.app_prefix}_firstname`);
  localStorage.removeItem(`${CONFIG.app_prefix}_lastname`);
  localStorage.removeItem(`${CONFIG.app_prefix}_birthday`);
  rest.defaults.headers.common['Authorization'] = '';
};

const user = async () => {
  return perform('/user', null);
};

/*
 * List Categories

 * PARAMS:
 * city: string|nullable
 * province: string|nullable
 * per_page: integer|nullable
 * page: integer|nullable
*/
const categories = async (city, province, page = 1, per_page = CONFIG.per_page) => {
  return perform('/categories', {
    city,
    province,
    page,
    per_page
  });
};

/*
 * List Companies
 *
 * PARAMS:
 * category_id: integer|required
 * city: string|nullable
 * province: string|nullable
 * per_page: integer|nullable
 * page: integer|nullable
 */
const companies = async (category_id, city, province, page = 1, per_page = CONFIG.per_page) => {
  return perform('/companies', {
    category_id,
    city,
    province,
    page,
    per_page
  });
};

/*
 * Get Company
 *
 * PARAMS:
 * 'company_id' => 'integer|required'
 */
const company = async (company_id) => {
  return perform('/company', { company_id });
};

/*
 * List Open days
 *
 * PARAMS:
 * company_id: integer|required
 * per_page: integer|nullable
 * page: integer|nullable
 */
const days = async (company_id, page = 1, per_page = CONFIG.per_page) => {
  return perform('/days', {
    company_id,
    page,
    per_page
  });
};

/*
 * List Open Time Slots
 *
 * PARAMS:
 * 'company_id' => 'integer|required'
 * 'date' => 'date_format:Y-m-d|required'
 */
const slots = async (company_id, date) => {
  return perform('/slots', {
    company_id,
    date
  });
};

/*
 * Create user booking
 *
 * PARAMS:
 * 'company_time_slot_id' => 'integer|required'
 * 'date' => 'date_format:Y-m-d|required'
 * 'name' => 'string|nullable'
 * 'lastname' => 'string|nullable'
 * 'birthday' => 'date_format:Y-m-d|nullable'
 */
const createBooking = async (company_time_slot_id, date, name, lastname, birthday) => {
  return perform('/booking', {
    company_time_slot_id,
    date,
    name,
    lastname,
    birthday
  }, 'POST');
};

/*
 * Add booking without Prenotation, just enter
 *
 * PARAMS:
 * 'company_time_slot_id' => 'integer|required'
 * 'date' => 'date_format:Y-m-d|required'
 */
const addBookingWithoutPrenotation = async (company_time_slot_id, date) => {
  return perform('/company/bookings/inc', {
    company_time_slot_id,
    date
  }, 'POST');
};


/*
 * List user bookings
 * GET | api/user/bookings
 *
 * PARAMS:
 * 'page' => 'integer'
 * 'per_page' => 'integer',
 * 'history' => 'boolean'
*/
const getBooking = async (history, page = 1, per_page = CONFIG.per_page) => {
  return perform('/user/bookings', {
    history,
    page,
    per_page
  });
};

/*
 * List company timeslot bookings
 *
 * PARAMS:
 * 'page' => 'integer'
 * 'per_page' => 'integer',
 * 'history' => 'boolean',
 * 'company_id' => 'integer|required'
 * 'company_time_slot_id' => 'integer' // se non presente torna tutti i timeslot
 */
const getCompanyBooking = async (company_time_slot_id = null, date,  page = 1, per_page = CONFIG.per_page) => {
  return perform('/company/bookings', {
    company_time_slot_id,
    date,
    page,
    per_page
  });
};

/*
 * List company timeslot bookings
 *
 * PARAMS:
 * 'page' => 'integer'
 * 'per_page' => 'integer',
 * 'history' => 'boolean',
 * 'company_id' => 'integer|required'
 * 'company_time_slot_id' => 'integer' // se non presente torna tutti i timeslot
 */
const getCompanyBookingThread = async (company_time_slot_id = null, date,  page = 1, per_page = CONFIG.per_page) => {
  return perform('/company/bookings', {
    company_time_slot_id,
    date,
    page,
    per_page
  }, 'GET', true);
};

/*
 * Update user booking
 *
 * PARAMS:
 * 'booking_id' => 'integer|required'
 * 'used' => 'boolean|required'
 */
const updateBooking = async (booking_id, used) => {
  return perform('/user/booking', {
    booking_id,
    used
  }, 'POST');
};

/*
 * Delete user booking
 *
 * PARAMS:
 * 'user_id' => 'integer'
 * 'booking_id' => 'integer|required'
 */
const deleteBooking = async (booking_id) => {
  return perform('/user/booking', {
    booking_id
  }, 'DELETE');
};

const apiToken = localStorage.getItem(`${CONFIG.app_prefix}_token`);
const userRole = localStorage.getItem(`${CONFIG.app_prefix}_role`);

const rest = axios.create({
  baseURL: CONFIG.api_url,
  headers: {
    common: {
      'X-Requested-With': 'XMLHttpRequest',
      'Accept': 'application/json',
    },
  },
});

if (apiToken) {
  rest.defaults.headers.common['Authorization'] = 'Bearer ' + apiToken;

  user()
    .then(response => {
      if (response.status === 200 && !userRole) {
        localStorage.setItem(`${CONFIG.app_prefix}_role`, response.data.role.name.toLowerCase());
      }
    })
    .catch(error => {
      console.log(error);
    });
}

export default {
  login,
  logout,
  user,
  register,
  categories,
  companies,
  company,
  days,
  slots,
  createBooking,
  getBooking,
  getCompanyBooking,
  getCompanyBookingThread,
  updateBooking,
  deleteBooking,
  addBookingWithoutPrenotation
}
