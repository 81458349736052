import CONFIG from './config.json';
import THEME_DEFAULT from './theme_default';
import THEME_PAM from './theme_pam';

import React from 'react';

import {
  HashRouter as Router,
  Switch,
  Route
} from 'react-router-dom';

import {
  ThemeProvider
} from "@material-ui/styles";

import { CssBaseline } from "@material-ui/core";

import {
  RestNotification
} from './components';

import {
  ScrollToTop
} from './utils';

import {
  Welcome,
  AboutUs,
  GdprPolicy,
  CustomerLogin,
  CustomerProfile,
  CustomerSearch,
  CategoryList,
  CompanyBooking,
  CompanyBookingResult,
  Company,
  CompanySlotList,
  CompanyList,
  OperatorLogin,
  OperatorCategoryList,
  OperatorCompany,
  OperatorCompanyList,
  OperatorCompanySlotList,
  OperatorCompanySlotInstructions,
  OperatorCompanySlot,
  OperatorBookingCompany,
  OperatorBookingCompanySlotList,
  OperatorBookingCompanyCreate,
  OperatorBookingCompanyCreateResult,
  ErrorPageForbidden,
  ErrorPage404
} from './pages';

require('moment/locale/it.js');

const AuthRoute = ({ children, ...params }) => {
  const apiToken = localStorage.getItem(`${CONFIG.app_prefix}_token`);
  const userRole = localStorage.getItem(`${CONFIG.app_prefix}_role`);
  const isGrant = apiToken && userRole === params.role;

  return (
    <Route
      {...params}
      render={({ location }) =>
        isGrant ? (
          children
        ) : (
          <ErrorPageForbidden />
        )
      }
    />
  );
}

const defaultRoutes = [
  {
    path: '/',
    role: null,
    component: <Welcome />
  },
  {
    path: '/login',
    role: null,
    component: <CustomerLogin />
  },
  {
    path: '/operator/login',
    role: null,
    component: <OperatorLogin />
  },
  {
    path: '/chi-siamo',
    role: null,
    component: <AboutUs />
  },
  {
    path: '/gdpr',
    role: null,
    component: <GdprPolicy />
  }
];

const customerRoutes = [
  {
    path: '/company/:id/:date/:slot/booking/:result',
    role: 'user',
    component: <CompanyBookingResult />
  },
  {
    path: '/company/:id/:date/:slot',
    role: 'user',
    component: <CompanyBooking />
  },
  {
    path: '/company/:id/:date',
    role: 'user',
    component: <CompanySlotList />
  },
  {
    path: '/company/:id',
    role: 'user',
    component: <Company />
  },
  {
    path: '/categories/:province/:city/:category',
    role: 'user',
    component: <CompanyList />
  },
  {
    path: '/categories/:province/:city',
    role: 'user',
    component: <CategoryList />
  },
  {
    path: '/search/:province/:city',
    role: 'user',
    component: <CustomerSearch />
  },
  {
    path: '/search',
    role: 'user',
    component: <CustomerSearch />
  },
  {
    path: '/profile',
    role: 'user',
    component: <CustomerProfile />
  }
];

const operatorRoutes = [
  {
    path: '/operator/booking/company/:id/:date/:slot/create/:result',
    role: 'operator',
    component: <OperatorBookingCompanyCreateResult />
  },
  {
    path: '/operator/booking/company/:id/:date/:slot',
    role: 'operator',
    component: <OperatorBookingCompanyCreate />
  },
  {
    path: '/operator/booking/company/:id/:date',
    role: 'operator',
    component: <OperatorBookingCompanySlotList />
  },
  {
    path: '/operator/booking/company/:id',
    role: 'operator',
    component: <OperatorBookingCompany />
  },
  {
    path: '/operator/company/:id/:date/:slot',
    role: 'operator',
    component: <OperatorCompanySlot />
  },
  {
    path: '/operator/company/:id/:date/:slot/instructions',
    role: 'operator',
    component: <OperatorCompanySlotInstructions />
  },
  {
    path: '/operator/company/:id/:date',
    role: 'operator',
    component: <OperatorCompanySlotList />
  },
  {
    path: '/operator/company/:id',
    role: 'operator',
    component: <OperatorCompany />
  },
  {
    path: '/operator/categories/:category',
    role: 'operator',
    component: <OperatorCompanyList />
  },
  {
    path: '/operator/categories',
    role: 'operator',
    component: <OperatorCategoryList />
  }
];

const routes = [
  ...defaultRoutes,
  ...customerRoutes,
  ...operatorRoutes
];

export default class App extends React.Component {
  render () {
    return (
      <ThemeProvider theme={ CONFIG.theme === 'default' ? THEME_DEFAULT : THEME_PAM }>
        <CssBaseline />
        <div className="App">
          <Router>
            <ScrollToTop />
            <Switch>
              {
                routes.map((route, index) => {
                  return (
                    route.role ? (
                      <AuthRoute
                        exact
                        key={ index }
                        role={ route.role }
                        path={ route.path }>
                          { route.component }
                      </AuthRoute>
                    ) : (
                      <Route
                        exact
                        key={ index }
                        path={ route.path }>
                          { route.component }
                      </Route>
                    )
                  )
                })
              }

              <Route exact path="*" component={ ErrorPage404 } />
            </Switch>

            <RestNotification />
          </Router>
        </div>
      </ThemeProvider>
    )
  }
}
