import React from 'react';

import {
  Box,
  Button,
  Container,
  Link as AnchorLink,
  Typography
} from '@material-ui/core';

import {
  Link,
  withRouter
} from 'react-router-dom';

import {
  PageHeader,
  Footer,
  HeaderBar
} from '../../components';

export default withRouter(class AboutUs extends React.Component {
  render () {
    return (
      <React.Fragment>
        <HeaderBar
          back="/"
          title="Home"
        />

        <PageHeader title="Chi siamo" />

        <main>
          <Container maxWidth="lg">
            <Box mb={3}>
              <Typography>
                CiaoCoda è un progetto di solidarietà sociale, ideato e realizzato da Fornace.
              </Typography>
              <br />
              <Typography>
                Durante l’emergenza Covid-19 abbiamo voluto unire le nostre competenze e forze per creare delle iniziative benefiche per la comunità, che possano essere sfruttate subito e gratuitamente.
              </Typography>
              <br />
              <Typography>
                Siamo un’agenzia creativa digitale con un debole per lo storytelling. I nostri progetti hanno una forte connotazione tecnologica e umana.
              </Typography>
              <br />
              <Typography>
                Cura del dettaglio, innovazione, metodo di lavoro agile; siamo un’agenzia “full-service”, in grado di gestire tutte le fasi strategico-operative.
              </Typography>
              <br />
              <Typography>
                Aiutiamo le aziende a diventare più snelle e produttive in ambito digitale.
              </Typography>
              <br />
              <Typography>
                Siamo specializzati in campagne multicanale, produzione foto e video, social strategy &amp; content creation, marketing automation, websites, apps e molto altro: <AnchorLink href="https://fornace.it/">https://fornace.it/"</AnchorLink>
              </Typography>
            </Box>

            <Box mb={3} textAlign="center">
              <Button
                size="large"
                variant="contained"
                color="primary"
                component={ Link }
                to="/">
                  Torna alla homepage
              </Button>
            </Box>
          </Container>
        </main>

        <Footer />
      </React.Fragment>
    )
  }
})
