import React from 'react';

import {
  Box,
} from '@material-ui/core';

import {
  withStyles
} from '@material-ui/core/styles';

const useStyles = theme => ({
  root: {
    width: '100%',
    display: 'flex',
    height: 8,
    flexWrap: 'wrap'
  },
  child: {
    height: 'inherit',
    flex: 1
  }
});

export default withStyles(useStyles)(class BrandBorder extends React.Component {
  constructor (props) {
    super(props);

    this.colors = [
      '#f9d06b', // Yellow
      '#ff9d1b', // Orange
      '#ec3940', // Red
      '#a32b93', // Magenta
      '#524992', // Purple
      '#0083b1', // Blue
      '#7dc400'  // Green
    ];
  }

  render () {
    return (
      <Box
        component="div"
        className={ `${this.props.classes.root} ${this.props.className}` }>
          {
            this.colors.map((item, index) => {
              return (
                <Box
                  key={ index }
                  className={ this.props.classes.child }
                  style={{ backgroundColor: item }}
                />
              )
            })
          }
      </Box>
    )
  }
})
