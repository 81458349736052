import React from 'react';

import {
  Box,
  Button,
  Container,
  Typography
} from '@material-ui/core';

import {
  Link,
  withRouter
} from 'react-router-dom';

import {
  PageHeader,
  Footer
} from '../../components';

export default withRouter(class ErrorPageForbidden extends React.Component {
  render () {
    return (
      <React.Fragment>
        <PageHeader title="Attenzione" />

        <main>
          <Container maxWidth="lg">
            <Box mb={3}>
              <Typography variant="h5" gutterBottom>Non puoi accedere a questa pagina.</Typography>
              <Typography gutterBottom>Ci dispiace ma purtroppo non possiedi i privilegi di accesso per accedere a questa pagina.</Typography>
            </Box>

            <Box mb={3} textAlign="center">
              <Button
                size="large"
                variant="contained"
                color="primary"
                component={ Link }
                to="/">
                  Torna alla homepage
              </Button>
            </Box>
          </Container>
        </main>

        <Footer />
      </React.Fragment>
    )
  }
})
