import React from 'react';

import {
  Box,
  Container,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction
} from '@material-ui/core';

import {
  withStyles,
  withTheme
} from '@material-ui/core/styles';

import {
  ArrowForwardIos as ArrowNextIcon,
  LocalParking as PriorityIcon
} from '@material-ui/icons';

import {
  Link,
  withRouter
} from 'react-router-dom';

import * as moment from 'moment';

import {
  HeaderBar,
  PageHeader,
  Footer
} from '../../components';

import {
  API
} from '../../utils';

moment.locale('it');

const useStyles = theme => ({
  list: {
    marginRight: 0,
    marginLeft: 0,
  },
  listArrowIcon: {
    right: 16,
    display: 'flex',
    alignItems: 'center'
  },
  listItem: {
    position: 'relative',
    marginBottom: '0.5rem',
    borderRadius: 16,
    overflow: 'hidden',
    paddingRight: 0
  },
  listPriorityIcon: {
    position: 'absolute',
    left: 16,
    minWidth: 'auto',
    borderRight: `1px solid ${theme.palette.common.white}`,
    paddingRight: 8
  },
  listItemText: {
    textAlign: 'center'
  },
  capitalize: {
    textTransform: 'capitalize'
  }
})

export default withTheme(withStyles(useStyles)(withRouter(class OperatorBookingCompanySlotList extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      company: undefined,
      date: undefined,
      slots: undefined,
      priorityDialog: false,
      selectedSlot: undefined
    };
  }

  goToBookingPage = (slot) => {
    this.props.history.push();
  }

  getSlotBackground = (slot) => {
    const thirthyPerc = Math.round(slot.actual_capacity * 30 / 100);

    if (slot.booking_count <= thirthyPerc) {
      return this.props.theme.palette.success.main;
    } else if (slot.booking_count > thirthyPerc && this.state.total < slot.actual_capacity) {
      return this.props.theme.palette.warning.main;
    } else {
      return this.props.theme.palette.error.main;
    }
  }

  onSlotButtuonClick = (slot) => {
    if (slot.protected !== 0) {
      this.setState({
        priorityDialog: true,
        selectedSlot: slot
      });
    } else {
      this.goToBookingPage(slot);
    }
  }

  onPriorityDialogClose = () => {
    this.setState({
      priorityDialog: false,
      selectedSlot: undefined
    });
  }

  onPriorityDialogConfirm = () => {
    this.goToBookingPage(this.state.selectedSlot);
  }

  onPriorityDialogAbort = () => {
    this.setState({
      priorityDialog: false,
      selectedSlot: undefined
    });
  }

  isSlotDisabled = (slot) => {
    const now = moment();
    const slotDay = moment(this.props.match.params.date);

    if (now.format('YYYY-MM-DD') === slotDay.format('YYYY-MM-DD')) {
      const slotEndTime = slot.to.split(':');
      const slotHoursTo = moment().hours(slotEndTime[0]).minutes(slotEndTime[1]);
      return now > slotHoursTo;
    } else {
      return false;
    }
  }

  componentDidMount () {
    API.slots(this.props.match.params.id, this.props.match.params.date)
      .then(response => {
        if (response.status === 200) {
          this.setState({
            company: response.data.company,
            date: response.data.date,
            slots: response.data.slots
          })
        }
      });
  }

  render () {
    return (
      <React.Fragment>
        <HeaderBar
          historyBack={ true }
          title="Indietro"
        />

        <PageHeader
          maxWidth="lg"
          sticky
          simple
          title={ this.state.company ? this.state.company.group.name : '' }
          text={ this.state.company ? this.state.company.address : '' }
        />

        <main>
          <Container maxWidth="lg">
            {
              this.state.date &&
                <Box mb={3}>
                  <Typography
                    className={ this.props.classes.capitalize }
                    variant="h2"
                    component="h1">
                      { moment(this.state.date).format('dddd DD') }
                  </Typography>

                  <Typography
                    className={ this.props.classes.capitalize }
                    variant="body1">
                      { moment(this.state.date).format('MMMM') }
                  </Typography>
                </Box>
            }

            {
              (this.state.slots && this.state.slots.length > 0) &&
                <List
                  className={ this.props.classes.list }
                  component="nav"
                  aria-label="activity list">
                  {
                    this.state.slots.map(item => {
                      const isDisabled = this.isSlotDisabled(item)

                      return (
                        <ListItem
                          className={ this.props.classes.listItem }
                          style={{ backgroundColor: this.getSlotBackground(item) }}
                          key={ item.id }
                          button
                          disabled={ isDisabled }
                          component={ Link }
                          to={ `/operator/booking/company/${this.state.company.id}/${this.state.date}/${item.id}` }
                          >
                            {
                              (item.protected !== 0) &&
                                <ListItemIcon className={ this.props.classes.listPriorityIcon }>
                                  <PriorityIcon />
                                </ListItemIcon>
                            }

                            <ListItemText
                              className={ this.props.classes.listItemText }
                              primaryTypographyProps={{ variant: 'h2', style: { fontWeight: 700 } }}
                              primary={ `${item.from.slice(0, 5)} - ${item.to.slice(0, 5)}` } />
                            <ListItemSecondaryAction
                              edge="end"
                              style={{ opacity: isDisabled ? .3 : 1 }}
                              className={ this.props.classes.listArrowIcon }>
                                <ArrowNextIcon />
                            </ListItemSecondaryAction>
                        </ListItem>
                      )
                    })
                  }
                </List>
            }

            {
              (this.state.slots && this.state.slots.length === 0) &&
                <Typography>Non ci sono risulati</Typography>
            }

          </Container>
        </main>

        <Footer />

      </React.Fragment>
    )
  }
})))
