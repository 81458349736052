import React from 'react';

import {
  Box,
  Button,
  Container,
  Typography
} from '@material-ui/core';

import {
  Link,
  withRouter
} from 'react-router-dom';

import {
  HeaderBar,
  PageHeader,
  Footer
} from '../../components';

export default withRouter(class GdprPolicy extends React.Component {
  render () {
    return (
      <React.Fragment>
        <HeaderBar
          back="/"
          title="Home"
        />

        <PageHeader title="Privacy Policy" />

        <main>
          <Container maxWidth="lg">
            <Box mb={4}>
              <Typography
                variant="h4"
                component="h2">
                  Informativa sul trattamento dei dati personali
              </Typography>
            </Box>

            <Box mb={4}>
              <Typography>
                Fornace S.r.l., in persona del legale rappresentante pro tempore, con sede legale in via Isonzo 11, Verona (VR) – Italia, C.F./P.Iva 04045160233 (“Fornace” o “Titolare”)
                ha a cuore la protezione dei dati personali dei suoi utenti. Questa informativa viene fornita ai sensi dell’art. 13 del Regolamento UE 2016/679 ("GDPR") per consentirti
                di comprendere come i tuoi dati personali vengono trattati e conservati nell’utilizzo della nostra piattaforma “Ciaocoda” (“Ciaocoda”).
                Le informazioni e i dati da te forniti o altrimenti acquisiti nell'ambito dell’utilizzo di Ciaocoda saranno oggetto di trattamento nel rispetto dei principi di liceità,
                correttezza, trasparenza, limitazione delle finalità e della conservazione, minimizzazione dei dati, esattezza, integrità e riservatezza.
              </Typography>
            </Box>

            <Box mb={4}>
              <Typography
                variant="h4"
                component="h2">
                  Titolare del trattamento
              </Typography>
            </Box>

            <Box mb={4}>
              <Typography>
                Il titolare del trattamento dei dati forniti o acquisiti tramite Ciaocoda è Fornace, come definito al precedente paragrafo.
                In qualunque momento potrai rivolgerti al Titolare per informazioni che riguardano il trattamento dei tuoi dati, oltre che per esercitare i tuoi diritti, ai seguenti contatti:
                telefono: 045 6837271
                casella di posta elettronica: privacy@fornacestudio.com
                casella di posta elettronica certificata (PEC): fornacestudio@pec.it.
                Inoltre, ti informiamo che il Titolare può avvalersi di responsabili del trattamento e di soggetti autorizzati al trattamento per il raggiungimento delle finalità che ti verranno illustrate di seguito.
              </Typography>
            </Box>

            <Box mb={4}>
              <Typography
                variant="h4"
                component="h2">
                  Dati personali oggetto del trattamento
              </Typography>
            </Box>

            <Box mb={4}>
              <Typography gutterBottom>
                L’accesso a Ciaocoda è consentito mediante l’inserimento del tuo numero di cellulare; Fornace invierà al tuo dispositivo un SMS contenente un codice numerico a sei cifre,
                che ti permetterà di prenotare nello slot preferito comunicando solo il tuo nome, cognome e data di nascita. Di seguito, l’elenco degli ulteriori dati personali che potranno essere trattati da Fornace attraverso Ciaocoda.
              </Typography>

              <Typography
                variant="h5"
                gutterBottom
                component="h3">
                  Dati che attengono al dispositivo
              </Typography>

              <Typography gutterBottom>
                Nel caso di utilizzo di Ciaocoda tramite app, cliccando sul pulsante “installa” dello store dedicato presti il tuo consenso esplicito all’installazione di Ciaocoda sul tuo dispositivo. Questo permette a Fornace di entrare in contatto con alcuni dati e alcune informazioni relative al tuo dispositivo, indispensabili affinché l’installazione vada a buon fine (es. modello del dispositivo, sistema operativo, risoluzione dello schermo, connessione di rete, lingua ecc.). L’unico modo per opporti al trattamento di queste informazioni è non installare o rimuovere la app Ciaocoda.
              </Typography>

              <Typography
                variant="h5"
                gutterBottom
                component="h3">
                  Dati forniti volontariamente dall’interessato
              </Typography>

              <Typography gutterBottom>
                Potrai utilizzare Ciaocoda non solo a fini strettamente personali, ma anche per procedere alla prenotazione di uno slot per conto di amici e familiari: in questo caso, rispetto ai dati così forniti sarai qualificato come autonomo titolare del trattamento, con conseguente assunzione di obblighi e responsabilità derivanti dalla normativa di volta in volta applicabile.
                Nel momento della comunicazione di dati personali di soggetti terzi, garantisci a Fornace di essere autorizzato al trattamento dei dati inseriti e di procedere al trattamento in conformità con la normativa sulla protezione dei dati, agendo sulla base di un’idonea base giuridica ai sensi dell’art. 6 GDPR. Conseguentemente, tieni indenne e manlevi il Titolare rispetto ad ogni contestazione, richiesta, pretesa, anche di natura risarcitoria, ecc. avanzate da tali soggetti terzi nei confronti di Fornace.
              </Typography>

              <Typography
                variant="h5"
                gutterBottom
                component="h3">
                  Codice Utente
              </Typography>

              <Typography gutterBottom>
                Al momento della prenotazione, ti verrà assegnato un identificativo casuale riferito al nome, cognome, data di nascita e alla specifica prenotazione effettuata (“Codice Utente”), che potrai utilizzare per usufruire della tua prenotazione presso l’esercizio commerciale. Per ciascuna prenotazione effettuata verrà generato un diverso Codice Utente.
              </Typography>

              <Typography
                variant="h5"
                gutterBottom
                component="h3">
                  Cookie e altri sistemi di tracciamento
              </Typography>

              <Typography gutterBottom>
                Tramite Ciaocoda non viene fatto uso di cookie per la profilazione degli utenti, né vengono impiegati altri metodi di tracciamento.
                Viene invece fatto uso di cookie di sessione (non persistenti), in modo strettamente limitato a quanto necessario per la navigazione sicura ed efficiente. La memorizzazione dei cookie di sessione nei dispositivi o nei browser è sotto il controllo dell'utente, laddove sui server, al termine delle sessioni HTTP, le informazioni relative ai cookie restano registrate nei log dei servizi, con tempi di conservazione comunque non superiori ai sette giorni, al pari degli altri dati di navigazione.
              </Typography>
            </Box>

            <Box mb={4}>
              <Typography
                variant="h4"
                component="h2">
                Finalità e base giuridica del trattamento. Natura del conferimento e conseguenze in caso di rifiuto
              </Typography>
            </Box>

            <Box mb={4}>
              <Typography>
                Fornace può trattare i tuoi dati personali per:
                <br/>
                <br/>- fornire il servizio della piattaforma Ciaocoda. La base giuridica del trattamento per le finalità di fornitura del servizio è costituita dalla necessità di soddisfare la tua richiesta di fruizione del servizio (art. 6, par. 1, lett. b, GDPR). Il conferimento dei tuoi dati per questa finalità è obbligatorio, essendo necessario a offrirti il servizio;
                <br/>- assolvere a eventuali obblighi di legge, contabili e fiscali. Questa finalità di trattamento ha come base giuridica l’art. 6, par. 1, lett. c, GDPR. Una volta conferiti i tuoi dati personali, il trattamento potrebbe essere dunque necessario per adempiere a un obbligo a cui è soggetto il Titolare;
                <br/>- rispondere a tue eventuali richieste in merito al trattamento dei dati, mediante l’utilizzo dei recapiti del Titolare. Anche in questo caso, la base giuridica del trattamento è l’art. 6, par. 1, lett. c, GDPR: una volta conferiti i tuoi dati, il trattamento è necessario per adempiere a un obbligo a cui è soggetto il Titolare ai sensi della normativa sulla protezione dei dati, fermo restando che l’eventuale rifiuto di fornire i dati non pregiudica la fruizione del servizio Ciaocoda.
              </Typography>
            </Box>

            <Box mb={4}>
              <Typography
                variant="h4"
                component="h2">
                Destinatari dei dati personali
              </Typography>
            </Box>

            <Box mb={4}>
              <Typography>
                I tuoi dati personali potranno essere condivisi, per le finalità esposte al paragrafo che precede, con:
                a) soggetti che agiscono in qualità di responsabili del trattamento nominati dal Titolare; soggetti con i quali sia necessario interagire per garantire il funzionamento di Ciaocoda, quali i soggetti incaricati di svolgere attività di manutenzione tecnica e di rete;
                b) soggetti che agiscono in qualità di autonomi titolari del trattamento, quali gli esercizi commerciali cui Fornace comunicherà i dati che hai fornito per effettuare la prenotazione, al fine di garantirti la fruizione del servizio.
                c) soggetti, enti o autorità a cui sia obbligatorio comunicare i tuoi dati personali in forza di ordini o disposizioni di legge, per la finalità di cui al secondo punto del paragrafo precedente;
                d) soggetti autorizzati dal Titolare al trattamento dei dati, che si siano impegnati alla riservatezza o su cui gravi un obbligo legale di riservatezza (es. dipendenti e collaboratori di Fornace).
                I tuoi dati personali non saranno in alcun modo diffusi, ceduti, venduti, né trasferiti al di fuori dell’Unione Europea.
              </Typography>
            </Box>

            <Box mb={4}>
              <Typography
                variant="h4"
                component="h2">
                Modalità e periodo di conservazione dei dati
              </Typography>
            </Box>

            <Box mb={4}>
              <Typography>
                Il trattamento dei dati avverrà con modalità manuali o elettroniche idonee a garantirne la sicurezza, nonché ad evitare accessi non autorizzati da parte di terzi, in un’ottica di minimizzazione rispetto alle finalità per le quali essi sono stati comunicati e raccolti.
                I dati personali conferiti per accedere a Ciaocoda (ossia, il tuo numero di telefono) verranno conservati per tutto il tempo in cui usufruirai del servizio, e per il tempo massimo di un anno dall’ultimo accesso a Ciaocoda.
                I dati personali inseriti per creare una prenotazione (nome, cognome, data di nascita) verranno conservati sino al termine di 24 ore successive alla fruizione della prenotazione.
                I dati personali trattati per la finalità di assolvimento di eventuali obblighi di legge, contabili e fiscali saranno conservati fino al tempo previsto dallo specifico obbligo o norma di legge applicabile.
                I dati personali trattati per rispondere alle tue richieste saranno conservati per il tempo strettamente necessario a risponderti.
                Successivamente – dunque, una volta venute meno le predette ragioni del trattamento – i dati saranno cancellati, distrutti o conservati in forma anonima.
              </Typography>
            </Box>

            <Box mb={4}>
              <Typography
                variant="h4"
                component="h2">
                  Diritti degli interessati
              </Typography>
            </Box>

            <Box mb={4}>
              <Typography>
                In relazione al trattamento dei tuoi dati personali, in qualità di interessato hai il diritto di proporre reclamo dinanzi all’Autorità di Controllo, che in Italia è il Garante per la Protezione dei Dati Personali, se ritieni che il trattamento in corso sia contrario alla normativa vigente.
                Inoltre, l’interessato ha i diritti di seguito elencati, che potrà far valere rivolgendo apposita richiesta al Titolare all’indirizzo privacy@fornacestudio.com o ai recapiti indicati in intestazione.
                Ai sensi degli articoli 15 e seguenti del GDPR, hai il diritto di chiedere a Fornace, in qualunque momento, l'accesso ai tuoi dati personali, la rettifica o la cancellazione degli stessi, nonché di richiedere la limitazione del trattamento nei casi previsti dall'art. 18 del GDPR.
                Per i dati conferiti per la finalità di fornitura del servizio e di risposta alle tue richieste, hai il diritto di ottenere in un formato strutturato, di uso comune e leggibile da dispositivo automatico i dati che ti riguardano, nei casi previsti dall'art. 20 del Regolamento (diritto alla portabilità dei dati).
                Per una migliore comprensione dei tuoi diritti, ti invitiamo in ogni caso a consultare per esteso gli articoli 15 e seguenti del GDPR.
              </Typography>
            </Box>

            <Box mb={4}>
              <Typography
                variant="h4"
                component="h2">
                  Modifiche dell’informativa
              </Typography>
            </Box>

            <Box mb={4}>
              <Typography>
                Questa informativa è in vigore alla data del 20/05/2020. Il Titolare potrà di volta in volta procedere a modifiche o aggiornamenti: in caso di variazioni che possano comportare un impatto rilevante sugli interessati, sarà cura di Fornace notificare agli stessi le modifiche intervenute.
              </Typography>
            </Box>

            <Box mb={3} textAlign="center">
              <Button
                size="large"
                variant="contained"
                color="primary"
                component={ Link }
                to="/">
                  Torna alla homepage
              </Button>
            </Box>
          </Container>
        </main>

        <Footer />
      </React.Fragment>
    )
  }
})
