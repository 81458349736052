import CONFIG from '../../config.json';

import React from 'react';

import {
  Box,
  Button,
  Container
} from '@material-ui/core';

import {
  withStyles
} from '@material-ui/core/styles';

import {
  Link,
  withRouter
} from 'react-router-dom';

import {
  PageHeader,
  Footer
} from '../../components';

import EvitaLaCodaLogo from '../../assets/images/logo-evitalacoda.png';

const useStyles = theme => ({
  container: {
    flex: 1,
    display: 'flex',
    alignItems: 'center'
  },
  button: {
    width: 240
  }
});

const logos = {
  evitaLaCoda: EvitaLaCodaLogo
};

export default withStyles(useStyles)(withRouter(class Welcome extends React.Component {
  componentDidMount () {
    const apiToken = localStorage.getItem(`${CONFIG.app_prefix}_token`);
    const userRole = localStorage.getItem(`${CONFIG.app_prefix}_role`);

    if (apiToken && userRole === 'user') {
      this.props.history.push('/search');
    }

    if (apiToken && userRole === 'operator') {
      this.props.history.push('/operator/categories');
    }
  }

  render () {
    return (
      <React.Fragment>
        <PageHeader
          logo={ CONFIG.logo ? logos[CONFIG.logo] : null }
          bigTitle={ (() => {
            return (
              !CONFIG.logo
                ? (
                  <span>
                    {
                      CONFIG.composed_title.split('|').map((text, index) => {
                        return index % 2 === 0
                          ? <span key={ index }>{ text }</span>
                          : <span key={ index } style={{ fontWeight: 300 }}>{ text }</span>
                      })
                    }
                  </span>
                ) :null
            )
          })()}
          abstract={ (() => {
            return (<span style={{ marginBottom: '2rem' }}><strong>Evita le code e tieni le distanze.</strong> Prenota i tuoi accessi alle strutture e i servizi essenziali con { CONFIG.title }</span>)
          })()}
        />

        <main>
          <Container maxWidth="lg" className={ this.props.classes.container }>
            <Box textAlign="center" width="100%">
              <Box mb={ 3 }>
                <Button
                  className={ this.props.classes.button }
                  size="large"
                  variant="contained"
                  color="primary"
                  component={ Link }
                  to="/login">
                    Accedi
                </Button>
              </Box>

              <Box mb={ 3 }>
                <Button
                  size="small"
                  component={ Link }
                  to="/operator/login">
                    Sono un operatore
                </Button>
              </Box>
            </Box>
          </Container>
        </main>

        <Footer />
      </React.Fragment>
    )
  }
}))
